import axios from 'axios';
import { useToast } from '../components/common/use-toast.tsx';

const useFetch = () => {
    const { toast } = useToast();

    const handleErrorMessage = (status, error = null) => {
        let errorMessage = '';

        if (status >= 200 && status < 300) {
            errorMessage = 'OK';
        } else if (status !== 422 && error.data !== null) {
            errorMessage = error.data.detail;
        } else if (status >= 400 && status < 600 && status !== 422) {
            errorMessage = error.data.detail[0].msg;
        }

        return errorMessage;
    };

    const makeApiCall = async (req) => {
        let responsePayload = {};

        await axios(req)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    responsePayload = {
                        data: res.data,
                        message: handleErrorMessage(res.status),
                        success: true,
                    };
                } else {
                    responsePayload = {
                        data: res.data,
                        message: handleErrorMessage(res.status, res),
                        success: false,
                    };
                    toast({
                        variant: 'destructive',
                        title: 'Uh oh! Something went wrong.',
                        description: handleErrorMessage(res.status, res),
                    });
                }
            })
            .catch((error) => {
                responsePayload = {
                    data: [],
                    message: handleErrorMessage(error.response.status, error.response),
                };
                toast({
                    variant: 'destructive',
                    title: 'Uh oh! Something went wrong.',
                    description: handleErrorMessage(error.response.status, error.response),
                });
            });

        return responsePayload;
    };

    return { handleErrorMessage, makeApiCall };
};

export default useFetch;

import { Form, FormField, FormItem, FormControl, FormMessage } from '@/components/common/form';
import { Input } from '@/components/common/input';
import { Select, SelectItem, SelectContent, SelectTrigger, SelectValue } from '@/components/common/select';
import type { FC } from 'react';

interface ProductSearchFormProps {
    formProps: any;
    _isLoading: boolean;
    _setIsLoading: boolean;
}
const ProductSearchForm: FC<ProductSearchFormProps> = ({ formProps, _isLoading, _setIsLoading }) => {
    const formBooleanOptions = [
        { label: 'True', value: true },
        { label: 'False', value: false },
        { label: 'Either', value: null },
    ];

    return (
        <Form {...formProps}>
            <div className="grid grid-cols-2 gap-4">
                <div className="pt-4">
                    <div className="flex text-md font-medium pb-2">Product Name</div>
                    <FormField
                        control={formProps.control}
                        name="name"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input className="mt-2" placeholder="Product Name" type="text" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="pt-4">
                    <div className="flex text-md font-medium pb-2">Product SKU</div>
                    <FormField
                        control={formProps.control}
                        name="sku"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input className="mt-2" placeholder="Product SKU" type="text" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="pt-4">
                    <div className="flex text-md font-medium pb-2">Customer Name</div>
                    <FormField
                        control={formProps.control}
                        name="customer"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input className="mt-2" placeholder="Customer Name" type="text" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="grid grid-cols-2 gap-2 pt-4">
                    <div className="flex text-md font-medium">Charge Rate From</div>
                    <div className="flex text-md font-medium">Charge Rate To</div>
                    <FormField
                        control={formProps.control}
                        name="unitChargeFrom"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input className="mt-2" placeholder="Charge from" type="number" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={formProps.control}
                        name="unitChargeTo"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input className="mt-2" placeholder="Charge to" type="number" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="pt-4">
                    <div className="flex text-md font-medium pb-2">Is a Kit</div>
                    <FormField
                        control={formProps.control}
                        name="isKit"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <SelectTrigger className="w-full">
                                            <SelectValue className="mt-2" placeholder="" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {formBooleanOptions.map((option) => (
                                                <SelectItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="pt-4">
                    <div className="flex text-md font-medium pb-2">Exclude from Pricing</div>
                    <FormField
                        control={formProps.control}
                        name="excludeFromPickRate"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <SelectTrigger className="w-full">
                                            <SelectValue className="mt-2" placeholder="" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {formBooleanOptions.map((option) => (
                                                <SelectItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="pt-4">
                    <div className="flex text-md font-medium pb-2">Count Kit as Single Unit</div>
                    <FormField
                        control={formProps.control}
                        name="countAsSingleUnit"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <SelectTrigger className="w-full">
                                            <SelectValue className="mt-2" placeholder="" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {formBooleanOptions.map((option) => (
                                                <SelectItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
        </Form>
    );
};

export default ProductSearchForm;

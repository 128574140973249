import cronstrue from 'cronstrue';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetFooter } from '../../common/sheet.tsx';

import { Button } from '@/components/ui/button';
import { Input } from '../../common/input.tsx';
import { ScrollArea } from '../../common/scroll-area.tsx';
import { Separator } from '../../common/separator.tsx';

import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '../../common/alert-dialog.tsx';

import StorageFeeEdit from './storageFeesEdit';
import useStorageApi from '../../../hooks/api/useStorageApi';
import { storageChargeType } from './forms/storageFeesForm';
import { Switch } from '../../common/switch.tsx';

const StorageFeesView = ({ open, setOpen, selectedStorageFee, locationData, customerData }) => {
    const { deleteStorageFee } = useStorageApi();
    const onDelete = (selectedStorageFeeId) => {
        deleteStorageFee(selectedStorageFeeId).then((res) => {
            if (res.success) {
                setOpen(false);
                window.location.reload();
            }
        });
    };

    return (
        selectedStorageFee && (
            <Sheet open={open} onOpenChange={setOpen}>
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">{selectedStorageFee.name}</SheetTitle>
                        <SheetDescription>View storage fee details below</SheetDescription>
                        <div className="pt-8">
                            <span className="flex text-xl font-medium">For all locations</span>
                            <div className="mt-2 flex items-center space-x-4">
                                <Switch checked={selectedStorageFee.all_locations} disabled />
                            </div>
                        </div>
                        {selectedStorageFee?.location_types?.length > 0 && (
                            <div className="pt-8">
                                <span className="flex text-xl font-medium">Locations</span>
                                <ScrollArea className="overflow-y-auto max-h-72 rounded-md border px-4 mt-2 pb-2">
                                    {selectedStorageFee.location_types.map((location, idx) => (
                                        <>
                                            <div className="w-100 pt-2 pr-2">{location.name}</div>
                                            {idx !== selectedStorageFee.location_types.length - 1 && <Separator className="my-2" />}
                                        </>
                                    ))}
                                </ScrollArea>
                            </div>
                        )}
                        <div className="pt-8">
                            <span className="flex text-xl font-medium">For all customers</span>
                            <div className="mt-2 flex items-center space-x-4">
                                <Switch checked={selectedStorageFee.all_customers} disabled />
                            </div>
                        </div>
                        {selectedStorageFee?.customers?.length > 0 && (
                            <div className="pt-8">
                                <span className="flex text-xl font-medium">Customers</span>
                                <ScrollArea className="overflow-y-auto max-h-72 rounded-md border px-4 mt-2 pb-2">
                                    {selectedStorageFee.customers.map((customer, idx) => (
                                        <>
                                            <div className="w-100 pt-2 pr-2">{customer.name}</div>
                                            {idx !== selectedStorageFee.customers.length - 1 && <Separator className="my-2" />}
                                        </>
                                    ))}
                                </ScrollArea>
                            </div>
                        )}
                        <div className="pt-8">
                            <span className="flex text-xl font-medium">Charging Cadence</span>
                            <Input
                                className="bg-white w-72 mt-2"
                                type="text"
                                placeholder={selectedStorageFee?.charge_calculation_cadence && cronstrue.toString(selectedStorageFee.charge_calculation_cadence, { verbose: true })}
                                disabled
                            />
                        </div>
                        <div className="pt-8">
                            <span className="flex text-xl font-medium">Empty location charge</span>
                            <span>If no items are present at a location, should we exclude it from storage charges?</span>
                            <div className="mt-2 flex items-center space-x-4">
                                <Switch checked={selectedStorageFee.exclude_empty_locations_from_charge} disabled />
                            </div>
                        </div>
                        <div className="pt-8">
                            <span className="flex text-xl font-medium">Charging Rate</span>
                            <Input
                                className="bg-white w-72 mt-2"
                                type="text"
                                placeholder={storageChargeType.find((type) => type.value === selectedStorageFee?.charge_type)?.label}
                                disabled
                            />
                        </div>
                        <div className="pt-8">
                            <span className="flex text-xl font-medium">Charge Amount</span>
                            <Input className="bg-white w-72 mt-2" type="text" placeholder={`$${parseFloat(selectedStorageFee?.charge_amount)?.toFixed(2)}`} disabled />
                        </div>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <AlertDialog>
                                <AlertDialogTrigger asChild>
                                    <Button variant="outline">Delete</Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Are you sure you want to delete {selectedStorageFee.name}?</AlertDialogTitle>
                                        <AlertDialogDescription>This action cannot be undone. This will permanently delete the rule.</AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction onClick={() => onDelete(selectedStorageFee.id)}>Delete</AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                            <StorageFeeEdit selectedStorageFee={selectedStorageFee} locationData={locationData} customerData={customerData} />
                        </div>
                    </SheetFooter>
                </SheetContent>
            </Sheet>
        )
    );
};

export default StorageFeesView;

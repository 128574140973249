export const getOrdinal = (n) => {
    let ord = `${n}th`;

    if (n % 10 === 1 && n % 100 !== 11) {
        ord = `${n}st`;
    } else if (n % 10 === 2 && n % 100 !== 12) {
        ord = `${n}nd`;
    } else if (n % 10 === 3 && n % 100 !== 13) {
        ord = `${n}rd`;
    }

    return ord;
};

export const getDropdownOptions = (values, valueLabelDict) => values.map((val) => ({ label: valueLabelDict[val], value: val }));

export const getDropdownValueObj = (value, options) => options.filter((option) => option.value === value)[0];

export const getDropdownValues = (values, options) => options.filter((option) => values.includes(option.value));

export const get12HourTime = (hourMinStr) => {
    const [hour, min] = hourMinStr.data.split(':');
    const newHour = hour % 12;
    const amPmStr = newHour < 1 ? ' AM' : ' PM';

    return `At ${newHour}:${min} ${amPmStr}`;
};

import * as z from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, SheetFooter } from '../../common/sheet.tsx';
import LoadingSpinner from '../../common/loadingSpinner';
import LocationEditForm from './forms/locationEditForm';
import useStorageApi from '../../../hooks/api/useStorageApi';

const LocationEdit = ({ selectedLocation, fetchLocations, setOpen }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { updateLocations } = useStorageApi();

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const locationSchema = z.object({
        name: z.string().trim().min(1, { message: 'Name is required' }),
        storage_type: dropDownSchema,
    });

    const formProps = useForm({
        resolver: zodResolver(locationSchema),
        defaultValues: {
            name: selectedLocation.name,
            storage_type: selectedLocation.storage_type
                ? {
                      label: selectedLocation.storage_type.name,
                      value: selectedLocation.storage_type.id,
                  }
                : {},
        },
    });

    const { handleSubmit } = formProps;

    const onSubmit = (values) => {
        setIsLoading(true);
        updateLocations({
            id: selectedLocation.id,
            name: values.name,
            storage_type_id: values.storage_type.value,
        }).then(async () => {
            await fetchLocations();
            setIsLoading(false);
            setIsDialogOpen(false);
            setOpen(false);
        });
    };

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                    className="ml-4"
                >
                    Edit
                </Button>
            </SheetTrigger>
            {isDialogOpen && (
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">Edit Location</SheetTitle>
                        <SheetDescription className="text-rails-dark-blue">
                            <LocationEditForm formProps={formProps} />
                        </SheetDescription>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <Button disabled={isLoading} onClick={handleSubmit((e) => onSubmit(e))} type="submit">
                                {isLoading && (
                                    <>
                                        <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                        Saving
                                    </>
                                )}
                                {!isLoading && <>Save</>}
                            </Button>
                        </div>
                    </SheetFooter>
                </SheetContent>
            )}
        </Sheet>
    );
};

export default LocationEdit;

/* eslint-disable react/no-unescaped-entities */
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';

const NotFound = () => (
    <div className="bg-white h-screen w-screen flex items-center justify-center">
        <div className="container flex flex-col medium:flex-row items-center justify-center medium:px-5">
            <div className="max-w-md medium:pl-5 flex flex-col items-center justify-center medium:items-start medium:justify-start">
                <div className="text-3xl medium:text-5xl font-poppinsExtraBold text-black">D'OH!</div>
                <p className="text-sm medium:text-xl font-poppinsBold leading-normal text-black">We couldn't find what you're looking for </p>
                <p className="mb-4 font-poppinsReg text-xs medium:text-xs text-black">Please check the entered link for any spelling errors</p>
                <Link to="/">
                    <Button type="button">Go To Homepage</Button>
                </Link>
            </div>
        </div>
    </div>
);

export default NotFound;

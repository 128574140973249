import './styles/index.css';
import App from './App';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

// Render the React app
const rootElement = document.getElementById('root');
if (rootElement) {
    createRoot(rootElement).render(
        <StrictMode>
            <App />
        </StrictMode>,
    );
} else {
    console.error('Root element not found');
}

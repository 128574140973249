import * as z from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Select from 'react-select';
import { Button } from '@/components/ui/button';
import { Form, FormField, FormItem, FormControl, FormMessage } from '../common/form.tsx';

import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
    AlertDialogFooter,
    AlertDialogCancel,
} from '../common/alert-dialog.tsx';
import useInvoiceApi from '../../hooks/api/useInvoiceApi';

const TimeZoneEdit = ({ currentTimeZone, timeZoneOptions, loadSettingsData }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { updateUserData } = useInvoiceApi();

    const dropdownSchema = z.object({
        label: z.string().optional(),
        value: z.string().optional(),
    });

    const customConfigSchema = z.object({
        time_zone: dropdownSchema,
    });

    const customConfigDefaultValues = {
        time_zone: [currentTimeZone].map((obj) => ({ label: obj, value: obj }))[0],
    };

    const formProps = useForm({
        resolver: zodResolver(customConfigSchema),
        defaultValues: customConfigDefaultValues,
    });

    const { control, formState, handleSubmit, reset } = formProps;

    const onSubmit = (values) => {
        setLoading(true);
        updateUserData(values.time_zone.value).then(async () => {
            reset();
            await loadSettingsData();
            setLoading(false);
            setIsDialogOpen(false);
        });
    };

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                    variant="outline"
                    size="sm"
                    className="mt-2"
                >
                    Update Time Zone
                </Button>
            </AlertDialogTrigger>
            {isDialogOpen && (
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Update Time Zone</AlertDialogTitle>
                        <AlertDialogDescription>
                            <h2 className="text-xs text-rails-dark-blue">The selected time zone will be applied for all operations and reporting in Rails</h2>
                            <div className="pt-8 pb-4">
                                <Form {...formProps}>
                                    <div>
                                        <div className="w-1/2">
                                            <FormField
                                                control={formProps.control}
                                                name="time_zone"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormControl>
                                                            <Select
                                                                {...field}
                                                                options={timeZoneOptions}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#DBF3D8',
                                                                        primary: '#92BAA3',
                                                                    },
                                                                })}
                                                                {...field}
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <Button onClick={handleSubmit((e) => onSubmit(e))} className={loading && 'animate-pulse'} type="submit">
                            {loading ? 'Submitting' : 'Submit'}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            )}
        </AlertDialog>
    );
};

export default TimeZoneEdit;

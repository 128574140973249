import { Input } from '../../../common/input.tsx';
import { Form, FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';

const PackageEditForm = ({ formProps }) => (
    <Form {...formProps}>
        <form className="space-y-8">
            <div className="pt-4">
                <div className="flex text-xl font-medium">Name</div>
                <FormField
                    control={formProps.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" placeholder="Enter package name" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-medium">Dimensions</div>
                <div className="grid grid-cols-3 gap-4">
                    <FormField
                        control={formProps.control}
                        name="length"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input className="mt-2" disabled placeholder="Enter package length" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={formProps.control}
                        name="width"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input className="mt-2" disabled placeholder="Enter package width" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={formProps.control}
                        name="height"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input className="mt-2" disabled placeholder="Enter package height" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-medium">Reference ID</div>
                <FormField
                    control={formProps.control}
                    name="reference_id"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" placeholder="Enter reference ID" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-medium">Package Rate</div>
                <FormField
                    control={formProps.control}
                    name="charge"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" placeholder="Enter package rate" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </form>
    </Form>
);

export default PackageEditForm;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';

import cronstrue from 'cronstrue';
import { DataTable } from '../../common/datatable/dataTable.tsx';
import { DataTableColumnHeader } from '../../common/datatable/dataTableColumnHeader.tsx';

import StorageFeeCreate from './storageFeesCreate';
import StorageFeeView from './storageFeesView';
import useStorageApi from '../../../hooks/api/useStorageApi';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi';
import { storageChargeType } from './forms/storageFeesForm';

const StorageFees = ({ showCreate = true }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [selectedStorageFee, setSelectedStorageFee] = useState(null);
    const [open, setOpen] = useState(false);

    const { getStorageFee, getStorageTypes } = useStorageApi();
    const { getInvoiceCustomers } = useInvoiceApi();

    const fetchStorageFees = async () => {
        getStorageFee().then((res) => {
            setTableData(res.data);
        });
    };

    const fetchLocationTypes = async () => {
        getStorageTypes().then((res) => {
            const filteredData = res.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setLocationData(filteredData);
        });
    };

    const fetchCustomers = async () => {
        getInvoiceCustomers().then((res) => {
            const filteredData = res.data
                .filter((customer) => customer.active === true)
                .filter((customer) => customer.invoice_customer_id === null)
                .map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            setCustomerData(filteredData);
        });
    };

    const openStorageFeesView = (row) => {
        setSelectedStorageFee(tableData.filter((data) => data.id === row.original.id)[0]);
        setOpen(true);
    };

    useEffect(() => {
        setIsLoading(true);
        fetchStorageFees();
        fetchLocationTypes();
        fetchCustomers();
        setIsLoading(false);
    }, []);

    const columns = [
        {
            accessorKey: 'name',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
            cell: ({ row }) => (
                <div className="w-[200px] font-medium hover:underline" onClick={() => openStorageFeesView(row)}>
                    <a href="#">{row.getValue('name')}</a>
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_type',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Charge Type" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">{storageChargeType.find((type) => type.value === row.getValue('charge_type'))?.label}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_amount',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Charge" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">${parseFloat(row.getValue('charge_amount'))?.toFixed(2)}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'location_types',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Location Type" />,
            cell: ({ row }) => {
                const locations = row.getValue('location_types');
                if (locations?.length > 0) {
                    const locationLabel = locations.map((location) => location.name);
                    const locationLabelsJoined = locationLabel.join(', ');
                    return <div className="customer-list">{locationLabelsJoined}</div>;
                }
                return 'All';
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'customers',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Customers" />,
            cell: ({ row }) => {
                const customers = row.getValue('customers');
                if (customers?.length > 0) {
                    const customerLabels = customers.map((customer) => customer.name);
                    const customerLabelsJoined = customerLabels.join(', ');
                    return <div className="customer-list">{customerLabelsJoined}</div>;
                }
                return 'All';
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_calculation_cadence',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Cadence" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        {row.getValue('charge_calculation_cadence') && cronstrue.toString(row.getValue('charge_calculation_cadence'), { verbose: true })}
                    </span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        // {
        //     accessorKey: 'active',
        //     header: ({ column }) => <DataTableColumnHeader column={column} title="Active" />,
        //     cell: ({ row }) => (
        //         <div className="z-99 w-[80px]">
        //             <Switch
        //                 checked={row.getValue('active')}
        //                 onCheckedChange={() => {
        //                     updateRuleActiveStatus(row.original.id, !row.getValue('active')).then(() => {
        //                         getActivePricingRules().then((res) => {
        //                             setTableData(res.data);
        //                         });
        //                     });
        //                 }}
        //             />
        //         </div>
        //     ),
        //     enableSorting: false,
        //     enableHiding: false,
        // },
    ];

    const DataTableComponent = () => (
        <DataTable
            loading={isLoading}
            data={tableData}
            columns={columns}
            ActionButton={showCreate ? <StorageFeeCreate locationData={locationData} customerData={customerData} /> : null}
            isMultiSelectRows={false}
            showActionButtonInToolbar
        />
    );

    const StorageFeeViewComponent = () => (
        <StorageFeeView open={open} setOpen={setOpen} selectedStorageFee={selectedStorageFee} locationData={locationData} customerData={customerData} />
    );

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Storage Fees</h1>
                            <h2 className="text-xs text-rails-dark-blue">Manage storage fees.</h2>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-20">{tableData && <DataTableComponent />}</div>
                        </div>
                    </div>
                </main>
            </div>

            <StorageFeeViewComponent />
        </div>
    );
};

export default StorageFees;

import Select from 'react-select';
import { Input } from '../../../common/input.tsx';
import { Form, FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';
import { lengthUnits } from '../../../../consts/vars';

const LocationCreateForm = ({ formProps }) => (
    <Form {...formProps}>
        <form className="space-y-8">
            <div className="pt-4">
                <div className="flex text-xl font-medium">Name</div>
                <FormField
                    control={formProps.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" placeholder="Enter location name" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-medium">Dimensions</div>
                <div className="grid grid-cols-3 gap-4">
                    <FormField
                        control={formProps.control}
                        name="length"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input className="mt-2" placeholder="Enter location length" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={formProps.control}
                        name="width"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input className="mt-2" placeholder="Enter location width" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={formProps.control}
                        name="height"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input className="mt-2" placeholder="Enter location height" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
            <div className="pt-4">
                <div className="grid">
                    <FormField
                        control={formProps.control}
                        name="length_unit"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            options={lengthUnits}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DBF3D8',
                                                    primary: '#92BAA3',
                                                },
                                            })}
                                        />
                                    </FormControl>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
        </form>
    </Form>
);

export default LocationCreateForm;

export const backendUri = import.meta.env.VITE_APP_BACKEND_API_HOST ?? 'http://localhost:8000';

export const scheduleTypeOptions = [
    // unlikely to need to schedule something every minute / hour
    // {
    //     label: 'minute',
    //     value: 'minute',
    // },
    // {
    //     label: 'hour',
    //     value: 'hour',
    // },
    {
        label: 'day',
        value: 'day',
    },
    {
        label: 'week',
        value: 'week',
    },
    {
        label: 'month',
        value: 'month',
    },
    {
        label: 'year',
        value: 'year',
    },
];

export const dayOptions = [
    {
        label: 'Monday',
        value: 1,
    },
    {
        label: 'Tuesday',
        value: 2,
    },
    {
        label: 'Wednesday',
        value: 3,
    },
    {
        label: 'Thursday',
        value: 4,
    },
    {
        label: 'Friday',
        value: 5,
    },
    {
        label: 'Saturday',
        value: 6,
    },
    {
        label: 'Sunday',
        value: 0,
    },
];

export const monthOptions = [
    {
        label: 'January',
        value: 1,
    },
    {
        label: 'Febuary',
        value: 2,
    },
    {
        label: 'March',
        value: 3,
    },
    {
        label: 'April',
        value: 4,
    },
    {
        label: 'May',
        value: 5,
    },
    {
        label: 'June',
        value: 6,
    },
    {
        label: 'July',
        value: 7,
    },
    {
        label: 'August',
        value: 8,
    },
    {
        label: 'September',
        value: 9,
    },
    {
        label: 'October',
        value: 10,
    },
    {
        label: 'November',
        value: 11,
    },
    {
        label: 'December',
        value: 12,
    },
];

export const filterSymbols = ['>', '<', '<=', '>=', '==', '!=', 'not in', 'in'];

export const filterSymbolOptions = [
    {
        label: 'greater than',
        value: '>',
    },
    {
        label: 'less than',
        value: '<',
    },
    {
        label: 'less than or equal to',
        value: '<=',
    },
    {
        label: 'greater than or equal to ',
        value: '>=',
    },
    {
        label: 'equals',
        value: '=',
    },
    {
        label: 'does not equal',
        value: '!=',
    },
    {
        label: 'not in',
        value: 'not in',
    },
    {
        label: 'in',
        value: 'in',
    },
];

export const filterSymbolsHumanReadable = {
    '>': 'greater than',
    '<': 'less than',
    '<=': 'less than or equal to',
    '>=': 'greater than or equal to ',
    '==': 'equals',
    '!=': 'does not equal',
    'not in': 'not in',
    in: 'in',
};

export const qbAllowedPaymentTypes = ['allow_ach', 'allow_credit_card'];

export const reportViewNames = {
    carrierDeliverySummary: {
        label: 'Carrier Delivery Summary',
        value: 'tracking.vw_carrier_delivery_summary',
    },
    shipmentLevelTracking: {
        label: 'Shipment Level Tracking',
        value: 'tracking.vw_shipment_status_time_summary',
    },
    shipmentExceptions: {
        label: 'Shipment Exceptions',
        value: 'tracking.vw_tracking_shipment_exceptions',
    },
};

export const reportViewDescriptions = {
    [reportViewNames.carrierDeliverySummary.value]: 'Tracking status updates at the carrier and service level consolidated into 9 categories',
    [reportViewNames.shipmentLevelTracking.value]: 'Cleaned and easy to understand checkpoint-level update for each tracking number',
    [reportViewNames.shipmentExceptions.value]: 'Checkpoint and last status information for shipments that have issues with delivery',
};

const reportColDates = {
    shipDate: {
        label: 'Ship Date',
        value: 'ship_date',
    },
    lastStatusTime: {
        label: 'Last Status Time',
        value: 'last_status_time',
    },
    exceptionTimestamp: {
        label: 'Exception Timestamp',
        value: 'exception_timestamp',
    },
};

export const reportDates = {
    week: {
        label: 'Week',
        value: 'week',
    },
    month: {
        label: 'Month',
        value: 'month',
    },
    quarter: {
        label: 'Quarter',
        value: 'quarter',
    },
    year: {
        label: 'Year',
        value: 'year',
    },
};

const reportAggTypes = {
    sum: {
        label: 'Sum',
        value: 'sum',
    },
    count: {
        label: 'Count',
        value: 'count',
    },
    countDistinct: {
        label: 'Count Distinct',
        value: 'count_distinct',
    },
};

const transformStringToOption = (str) => ({
    label: str.replaceAll('_', ' '),
    value: str,
});

export const reportConfigDefaultValueByView = {
    [reportViewNames.carrierDeliverySummary.value]: {
        view_name: reportViewNames.carrierDeliverySummary,
        type_date: reportDates.month,
        col_date: reportColDates.shipDate,
        type_agg: reportAggTypes.sum,
        cols_agg_by: ['shipping_carrier'].map((str) => transformStringToOption(str)),
        cols_agg: [
            'total_shipments',
            'info_received',
            'in_transit',
            'out_for_delivery',
            'failed_attempt',
            'delivered',
            'available_for_pickup',
            'exception',
            'pending',
            'expired',
        ].map((str) => transformStringToOption(str)),
    },
    [reportViewNames.shipmentLevelTracking.value]: {
        view_name: reportViewNames.shipmentLevelTracking,
        type_date: reportDates.year,
        col_date: reportColDates.lastStatusTime,
        type_agg: reportAggTypes.count,
        cols_agg_by: ['shipping_carrier'].map((str) => transformStringToOption(str)),
        cols_agg: ['info_received', 'in_transit', 'out_for_delivery', 'failed_attempt', 'delivered', 'available_for_pickup', 'exception', 'pending', 'expired'].map((str) =>
            transformStringToOption(str),
        ),
    },
    [reportViewNames.shipmentExceptions.value]: {
        view_name: reportViewNames.shipmentExceptions,
        type_date: reportDates.month,
        col_date: reportColDates.exceptionTimestamp,
        type_agg: reportAggTypes.count,
        cols_agg_by: ['shipping_carrier'].map((str) => transformStringToOption(str)),
        cols_agg: ['exception_description'].map((str) => transformStringToOption(str)),
    },
};

export const reportViewNamesOptions = [reportViewNames.carrierDeliverySummary, reportViewNames.shipmentLevelTracking, reportViewNames.shipmentExceptions];
export const reportColDatesOptions = [reportColDates.shipDate, reportColDates.lastStatusTime, reportColDates.exceptionTimestamp];
export const reportDatesOptions = [reportDates.week, reportDates.month, reportDates.quarter, reportDates.year];
export const reportTypeAggOptions = [reportAggTypes.sum, reportAggTypes.count, reportAggTypes.countDistinct];
export const reportDefaultValuesByViewLocalStorageKey = 'railsReportConfigDefaultValuesByView';
export const reportDefaultViewLocalStorageKey = 'railsReportDefaultView';

export const RuleConditionCol = {
    order_number: 'Order Number',
    company_order_source: 'Order Channel',
    fulfillment_status: 'Fulfillment Status',
    order_price: 'Order Price',
    shipping_carrier: 'Carrier',
    shipping_service_level: 'Service Level',
    shipping_charge: 'Shipping Charge',
    package: 'Package',
    package_weight: 'Package Weight',
    shipment_address_state: 'Destination State',
    shipment_address_country: 'Destination Country',
    package_insurance_amount: 'Package Insurance Amount',
    items: 'Items',
    address_classification: 'Address Classification',
};

export const TierConditionCol = {
    num_items: 'number of items',
    num_unique_skus: 'number of unique SKUs',
    num_shipments: 'number of shipments',
    num_packages: 'number of packages',
};

export const PricingMethodCol = {
    fixed_cost: 'fixed cost',
    margin_percent: 'percent markup',
    margin_dollar: 'dollar markup',
    variable_cost: 'per ',
};

export const PricingMethodMarginCol = {
    carrier_acc: 'carrier accessorial',
    carrier_base: 'carrier base',
    carrier_total: 'carrier total',
    label_acc: 'label accessorial',
    label_base: 'label base',
    label_total: 'label total',
};

export const PricingMethodVariableCol = {
    num_items: 'item',
    num_unique_skus: 'unique SKU',
    num_shipments: 'shipment',
    num_packages: 'package',
};

export const chargeLevel = {
    invoice_level: 'Invoice',
    package_level: 'Package',
};

export const invoiceConfigDateTypeOptions = [
    { label: 'Order Date', value: 'order' },
    { label: 'Ship Date', value: 'ship' },
];

export const invoiceConfigChargeTypeOptions = [
    { label: 'Label Charge', value: 'label' },
    { label: 'Carrier Bill', value: 'carrier' },
];

export const invoiceConfigShippingInvoiceOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

export const invoiceConfigDeliveryMethodOptions = [
    { label: 'Quickbooks', value: 'erp_quickbooks' },
    { label: 'Email', value: 'email' },
];

export const cronUnits = [
    {
        type: 'minutes',
        min: 0,
        max: 59,
        total: 60,
    },
    {
        type: 'hours',
        min: 0,
        max: 23,
        total: 24,
    },
    {
        type: 'month-days',
        min: 1,
        max: 31,
        total: 31,
    },
    {
        type: 'months',
        min: 1,
        max: 12,
        total: 12,
        // DO NO EDIT
        // Only used internally for Cron syntax
        // alt values used for labels are in ./locale.ts file
        alt: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
    },
    {
        type: 'week-days',
        min: 0,
        max: 6,
        total: 7,
        // DO NO EDIT
        // Only used internally for Cron syntax
        // alt values used for labels are in ./locale.ts file
        alt: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    },
];

export const billingPeriodCutoffTimeHour = 0;
export const billingPeriodCutoffTimeMinute = 0;
export const billingPeriodCutoffTime = `${billingPeriodCutoffTimeHour}:${billingPeriodCutoffTimeMinute}`;

export const lengthUnits = [
    {
        label: 'Feet',
        value: 'FT',
    },
    {
        label: 'Meters',
        value: 'M',
    },
    {
        label: 'Inches',
        value: 'IN',
    },
    {
        label: 'Centimeters',
        value: 'CM',
    },
];

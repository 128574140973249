/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';

import { NumericFormat } from 'react-number-format';
import { MoreHorizontal } from 'lucide-react';
import { DataTable } from '../../common/datatable/dataTable.tsx';
import { DataTableColumnHeader } from '../../common/datatable/dataTableColumnHeader.tsx';
import useInvoiceApi from '@/hooks/api/useInvoiceApi';

import PackageCreateRate from './packageCreate.jsx';
import PackageView from './packageView.jsx';
import { getInvoiceRuleDisplayName } from '@/utils/invoice.jsx';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../common/dropdown-menu.tsx';
import { Button } from '@/components/ui/button';

const Packages = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [open, setOpen] = useState(false);
    const { deletePackage, getPackages } = useInvoiceApi();

    const fetchPackages = async () => {
        setIsLoading(true);
        getPackages().then((res) => {
            setTableData(res.data);
            setIsLoading(false);
        });
    };

    const openPackageView = (row) => {
        setSelectedPackage(tableData.filter((data) => data.id === row.original.id)[0]);
        setOpen(true);
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    const columns = [
        {
            accessorKey: 'name',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
            cell: ({ row }) => (
                <div className="w-[200px] font-medium hover:underline" onClick={() => openPackageView(row)}>
                    <a href="#">{getInvoiceRuleDisplayName(row.getValue('name'))}</a>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'reference_id',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Reference ID" />,
            cell: ({ row }) => <div className="w-[100px] font-medium">{row.getValue('reference_id')}</div>,
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'length',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Length" />,
            cell: ({ row }) => (
                <div className="flex space-x-2">
                    <span className="max-w-[500px] text-wrap">{row.getValue('length')}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'width',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Width" />,
            cell: ({ row }) => (
                <div className="flex space-x-2">
                    <span className="max-w-[500px] text-wrap">{row.getValue('width')}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'height',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Height" />,
            cell: ({ row }) => (
                <div className="flex space-x-2">
                    <span className="max-w-[500px] text-wrap">{row.getValue('height')}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'charge',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Charge" />,
            cell: ({ row }) => (
                <div className="flex space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        <NumericFormat
                            value={row.getValue('charge') ? row.getValue('charge') : null}
                            displayType="text"
                            thousandSeparator=","
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem
                            onClick={() => {
                                deletePackage(row.original.id).then(() => {
                                    fetchPackages();
                                });
                            }}
                        >
                            Delete Package
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            ),
        },
    ];

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Packages</h1>
                            <h2 className="text-xs text-rails-dark-blue">Manage packages used on invoices.</h2>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-20">
                                {tableData && (
                                    <DataTable
                                        loading={isLoading}
                                        data={tableData}
                                        columns={columns}
                                        ActionButton={<PackageCreateRate fetchPackages={fetchPackages} />}
                                        isMultiSelectRows={false}
                                        isDownloadable
                                        downloadData={tableData}
                                        tableName="packages"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <PackageView open={open} setOpen={setOpen} selectedPackage={selectedPackage} />
        </div>
    );
};

export default Packages;

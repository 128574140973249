import { useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Loader2, AlertCircle } from 'lucide-react';
import { useAuth } from './AuthProvider.tsx';
import { Input } from '../common/input.tsx';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription, AlertTitle } from '../common/alert.tsx';
import logo from '../../assets/logo_only_green_on_transparent.png';
import bgImg from '../../assets/bg_road_1.jpeg';

const Login = () => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setLoading(true);

        login(emailRef.current.value, passwordRef.current.value)
            .then(() => {
                setLoading(false);
                if (searchParams.get('redirect')) {
                    navigate(searchParams.get('redirect'));
                } else {
                    navigate('/notifications');
                }
            })
            .catch((e) => {
                setError('Sign in failed, please try again.');
                setLoading(false);
            });
    };

    return (
        <div className="flex h-full bg-gray-100">
            <div className="flex flex-1 flex-col justify-center sm:px-6 lg:flex-none lg:pr-28 xl:pr-28">
                {error && (
                    <Alert className="flex flex-col rounded-lg bg-red-100 border-red-400" variant="default">
                        <AlertCircle className="h-4 w-4" />
                        <AlertTitle>Error</AlertTitle>
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}
                <div className="mx-auto w-full max-w-sm pr-24 lg:pr-8 lg:w-96 content-center">
                    <div>
                        <img className="object-cover h-42 w-72 lg:ml-8" src={logo} alt="Rails" />
                    </div>
                    <div>
                        <div>
                            <form onSubmit={handleSubmit} className="xtra-small:w-80 flex flex-col rounded-lg">
                                <Input ref={emailRef} className="mb-2 placeholder-rails-dark-blue border-gray-300 text-rails-gray-300" type="email" placeholder="Email" />
                                <Input
                                    ref={passwordRef}
                                    className="mt-8 mb-6 placeholder-rails-dark-blue border-gray-300 text-rails-gray-300"
                                    required
                                    type="password"
                                    placeholder="Password"
                                />

                                {loading && (
                                    <Button disabled className="mt-4 bg-rails-dark-blue mb-4">
                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                        Please Wait
                                    </Button>
                                )}

                                {!loading && <Button className="mt-4 bg-rails-dark-green text-white mb-4 hover:bg-rails-med-green">Log In</Button>}

                                <Button className="text-rails-dark-blue" variant="link">
                                    <Link to="/forgot-password">Forgot Password?</Link>
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative hidden w-0 flex-1 lg:block">
                <img className="absolute inset-0 h-full w-full object-cover" src={bgImg} alt="" />
            </div>
        </div>
    );
};

export default Login;

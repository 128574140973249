import { CheckCircleIcon, IdentificationIcon } from '@heroicons/react/24/outline';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetFooter } from '../common/sheet.tsx';
import { Switch } from '../common/switch.tsx';
import CustomerEdit from './customerEdit';

const CustomerView = ({ open, setOpen, selectedCustomer, customerList }) =>
    selectedCustomer && (
        <Sheet open={open} onOpenChange={setOpen}>
            <SheetContent position="right" size="lg">
                <SheetHeader className="pb-36 overflow-y-auto h-full">
                    <SheetTitle className="text-3xl">{selectedCustomer.name}</SheetTitle>
                    <SheetDescription>View customer details below</SheetDescription>

                    <div className="pt-4">
                        <div className="flex text-xl font-medium">
                            <CheckCircleIcon className="h-6 w-6 mr-2" />
                            Active
                        </div>
                        <div className="mt-2 flex items-center space-x-4">
                            <Switch checked={selectedCustomer.active} disabled />
                        </div>
                    </div>

                    <div className="pt-8">
                        <div className="flex text-xl font-medium">
                            <IdentificationIcon className="h-6 w-6 mr-2" />
                            Reference ID
                        </div>
                        <div className="w-100 pr-2 pt-2">
                            {selectedCustomer.customer_reference_id && selectedCustomer.customer_reference_id}
                            {!selectedCustomer.customer_reference_id && 'No reference ID set.'}
                        </div>
                    </div>
                    <div className="pt-8">
                        <div className="flex text-xl font-medium">
                            <IdentificationIcon className="h-6 w-6 mr-2" />
                            Map to other Customer for Invoicing?
                        </div>
                        <div className="w-100 pr-2 pt-2">
                            {selectedCustomer.invoice_customer_id ? customerList.filter((data) => data.id === selectedCustomer.invoice_customer_id)[0].name : 'No.'}
                        </div>
                    </div>
                </SheetHeader>
                <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                    <div>
                        <CustomerEdit selectedCustomer={selectedCustomer} customerList={customerList} />
                    </div>
                </SheetFooter>
            </SheetContent>
        </Sheet>
    );
export default CustomerView;

import Papa from 'papaparse';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { Button } from '@/components/ui/button';

interface DownloadButtonProps {
    jsonData: Record<string, any>[];
    tableName: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ jsonData, tableName }) => {
    const handleDownload = (): void => {
        let csvData: string = '';

        // remove company ID from file
        if (jsonData.length > 0) {
            const listOfObjs: string[] = Object.keys(jsonData[0]);

            if (listOfObjs.includes('company_id')) {
                listOfObjs.splice(listOfObjs.indexOf('company_id'), 1);
            }
            csvData = Papa.unparse(jsonData, { columns: listOfObjs });
        } else {
            csvData = Papa.unparse(jsonData);
        }

        // Create a Blob object with CSV data
        const blob: Blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

        // Create a temporary URL for the Blob
        const url: string = URL.createObjectURL(blob);

        // Create a temporary link element
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = url;
        link.download = `rails_${tableName.toLowerCase()}${new Date().toISOString().slice(0, 10).replace(/-/g, '')}_report.csv`;

        // Append the link to the DOM and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up the temporary URL and link
        URL.revokeObjectURL(url);
        // @ts-ignore
        document.body.removeChild(link);
    };

    return (
        <Button size="sm" variant="outline" className="ml-4 h-8 text-sm  float-right" onClick={handleDownload}>
            <ArrowDownTrayIcon className="h-4 w-4 mr-2" />
            Download
        </Button>
    );
};

export default DownloadButton;

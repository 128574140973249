import { GlobeAltIcon, QueueListIcon } from '@heroicons/react/24/outline';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetFooter } from '../../common/sheet.tsx';

import { Button } from '@/components/ui/button';
import { Input } from '../../common/input.tsx';
import { Switch } from '../../common/switch.tsx';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '../../common/alert-dialog.tsx';

import useInvoiceApi from '@/hooks/api/useInvoiceApi';
import PricingCategoryEdit from './pricingCategoryEdit.tsx';

const PricingCategoryView = ({ openCategory, setOpenCategory, pricingCategory, setPricingCategory, chargeLevels }) => {
    const { deleteInvoiceChargeTypes } = useInvoiceApi();
    const onDelete = (pricingCategoryId) => {
        deleteInvoiceChargeTypes(pricingCategoryId).then(() => {
            setOpenCategory(false);
            window.location.reload();
        });
    };

    return (
        pricingCategory && (
            <Sheet open={openCategory} onOpenChange={setOpenCategory}>
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">{pricingCategory && `${pricingCategory.name}`}</SheetTitle>
                        <SheetDescription className="text-md">{pricingCategory.description}</SheetDescription>

                        <div className="pt-4">
                            <div>Mentioned on customer invoices as </div>
                            <div className="italic font-bold">{pricingCategory.charge_on_invoice}</div>
                        </div>

                        <div className="pt-4">
                            <div className="flex text-xl font-medium">
                                <GlobeAltIcon className="h-6 w-6 mr-2" />
                                Required
                            </div>
                            <div className="mt-2 flex items-center space-x-4">
                                <Switch checked={pricingCategory.required} disabled />
                            </div>
                        </div>

                        <div className="pt-4">
                            <div className="flex text-xl font-medium">
                                <QueueListIcon className="h-6 w-6 mr-2" />
                                Charge Application Level
                            </div>
                            <div className="mt-2 flex items-center space-x-4">
                                <Input
                                    className="bg-white w-72 mt-2"
                                    type="text"
                                    placeholder={chargeLevels.filter((charge) => charge.value === pricingCategory.charge_level)[0].label}
                                    disabled
                                />
                            </div>
                        </div>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <AlertDialog>
                                <AlertDialogTrigger asChild>
                                    <Button variant="outline">Delete</Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Are you sure you want to delete {pricingCategory.name}?</AlertDialogTitle>
                                        <AlertDialogDescription>This action cannot be undone. This will permanently delete the rule.</AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction onClick={() => onDelete(pricingCategory.id)}>Delete</AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                            <PricingCategoryEdit pricingCategoryValues={pricingCategory} setPricingCategory={setPricingCategory} chargeLevels={chargeLevels} />
                        </div>
                    </SheetFooter>
                </SheetContent>
            </Sheet>
        )
    );
};

export default PricingCategoryView;

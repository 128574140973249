import Cookies from 'universal-cookie';

const getRequestHeader = () => {
    const cookies = new Cookies();
    const header = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('auth_token')}`,
    };

    return header;
};

export { getRequestHeader };

import * as z from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, SheetFooter } from '../../common/sheet.tsx';
import PackageCreateForm from './forms/packageCreateForm';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi';
import LoadingSpinner from '../../common/loadingSpinner';

const PackageCreateRate = ({ fetchPackages }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { createPackage } = useInvoiceApi();

    const packageSchema = z.object({
        name: z.string().trim().min(1, { message: 'Name is required' }),
        length: z.string().trim().min(1, { message: 'Length (in inches) is required' }),
        width: z.string().trim().min(1, { message: 'Width (in inches) is required' }),
        height: z.string().trim().min(1, { message: 'Height (in inches) is required' }),
        charge: z.string().optional(),
        reference_id: z.string().optional(),
    });

    const formProps = useForm({
        resolver: zodResolver(packageSchema),
    });

    const { handleSubmit } = formProps;

    const onSubmit = (values) => {
        setIsLoading(true);
        createPackage(values).then(async () => {
            await fetchPackages();
            setIsLoading(false);
            setIsDialogOpen(false);
        });
    };

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                    size="sm"
                    className="ml-4 h-8 text-sm"
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Create Package Rule
                </Button>
            </SheetTrigger>
            {isDialogOpen && (
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">Create New Package</SheetTitle>
                        <SheetDescription className="text-rails-dark-blue">
                            <PackageCreateForm formProps={formProps} />
                        </SheetDescription>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <Button disabled={isLoading} onClick={handleSubmit((e) => onSubmit(e))} type="submit">
                                {isLoading && (
                                    <>
                                        <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                        Creating
                                    </>
                                )}
                                {!isLoading && <>Create</>}
                            </Button>
                        </div>
                    </SheetFooter>
                </SheetContent>
            )}
        </Sheet>
    );
};

export default PackageCreateRate;

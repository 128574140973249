import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetFooter, SheetTitle } from '../../common/sheet.tsx';
import { Input } from '../../common/input.tsx';

import useStorageApi from '../../../hooks/api/useStorageApi';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '../../common/alert-dialog.tsx';
import { Button } from '@/components/ui/button';

const StorageTypeView = ({ open, setOpen, selectedStorageType }) => {
    const { deleteStorageTypes, getStorageTypes } = useStorageApi();
    const fetchStorageTypes = async () => {
        getStorageTypes();
    };

    const onDelete = (storageTypeId) => {
        deleteStorageTypes(storageTypeId).then((res) => {
            if (res.success) {
                window.location.reload();
            }
        });
    };

    return (
        selectedStorageType && (
            <Sheet open={open} onOpenChange={setOpen}>
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">{selectedStorageType && `${selectedStorageType.name}`}</SheetTitle>
                        <SheetDescription>View storage type details below</SheetDescription>
                        <div className="pt-8">
                            <span className="flex text-xl font-medium">Length</span>
                            <Input className="bg-white w-72 mt-2" type="text" placeholder={selectedStorageType?.dimensions?.length} disabled />
                        </div>
                        <div className="pt-4">
                            <span className="flex text-xl font-medium">Width</span>
                            <Input className="bg-white w-72 mt-2" type="text" placeholder={selectedStorageType?.dimensions?.width} disabled />
                        </div>
                        <div className="pt-4">
                            <span className="flex text-xl font-medium">Height</span>
                            <Input className="bg-white w-72 mt-2" type="text" placeholder={selectedStorageType?.dimensions?.height} disabled />
                        </div>
                        <div className="pt-4">
                            <span className="flex text-xl font-medium">Units</span>
                            <Input className="bg-white w-72 mt-2" type="text" placeholder={selectedStorageType?.dimensions?.length_unit} disabled />
                        </div>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <AlertDialog>
                                <AlertDialogTrigger asChild>
                                    <Button variant="outline">Delete</Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Are you sure you want to delete {selectedStorageType.name}?</AlertDialogTitle>
                                        <AlertDialogDescription>This action cannot be undone. This will permanently delete the storageType.</AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction onClick={() => onDelete(selectedStorageType.id)}>Delete</AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                            {/* <StorageTypeEdit selectedStorageType={selectedStorageType} fetchStorageTypes={fetchStorageTypes} /> -- disabling dims but keeping units editable makes no sense. disabling form for now */}
                        </div>
                    </SheetFooter>
                </SheetContent>
            </Sheet>
        )
    );
};

export default StorageTypeView;

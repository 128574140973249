import { UsersIcon, PencilSquareIcon, AdjustmentsHorizontalIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { useState, useEffect } from 'react';

import Select from 'react-select';

import { Input } from '../../../common/input.tsx';
import { Form, FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';
import useInvoiceApi from '@/hooks/api/useInvoiceApi';
import PricingRuleDetailsForm from './pricingRuleDetailsForm.jsx';
import { Switch } from '../../../common/switch.tsx';
import LoadingSpinner from '../../../common/loadingSpinner.jsx';

const PricingRuleForm = ({ formProps, ruleConditions }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [activeInvoiceCustomers, setActiveInvoiceCustomers] = useState([]);
    const [invoiceTypes, setInvoiceTypes] = useState([]);
    const [invoiceUiOptions, setInvoiceUiOptions] = useState({});
    const { getInvoiceCustomers, getInvoiceChargeTypes, getInvoiceUiOptions } = useInvoiceApi();

    useEffect(() => {
        Promise.all([getInvoiceCustomers(), getInvoiceChargeTypes(), getInvoiceUiOptions()]).then((res) => {
            const customerList = res[0].data
                .filter((customer) => customer.active === true)
                .filter((customer) => customer.invoice_customer_id === null)
                .map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            const filteredData = res[1].data.filter((item) => !item.name.includes('_based_on_carrier_bill'));
            setActiveInvoiceCustomers(customerList);
            setInvoiceTypes(filteredData);
            setInvoiceUiOptions(res[2]);
            setIsLoading(false);
        });
    }, []);

    return (
        <>
            <LoadingSpinner loading={isLoading} />
            {!isLoading && (
                <Form {...formProps}>
                    <form className="space-y-8">
                        <div className="pt-4">
                            <div className="flex text-xl font-medium">
                                <PencilSquareIcon className="h-6 w-6 mr-2" />
                                Name
                            </div>
                            <FormField
                                control={formProps.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input className="mt-2" placeholder="Enter pricing rule name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="pt-4">
                            <div className="flex text-xl font-medium">
                                <GlobeAltIcon className="h-6 w-6 mr-2" />
                                Default
                            </div>
                            <div className="mt-2 flex items-center space-x-4">
                                <FormField
                                    control={formProps.control}
                                    name="default"
                                    render={({ field }) => (
                                        <>
                                            <FormItem>
                                                <FormControl>
                                                    <Switch onCheckedChange={field.onChange} checked={field.value} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                            <span>Is this a default pricing rule?</span>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        {!formProps.getValues('default') && (
                            <div className="pt-4">
                                <div className="flex text-xl font-medium">
                                    <UsersIcon className="h-6 w-6 mr-2" />
                                    Customers
                                </div>
                                <div className="mt-2">
                                    <FormField
                                        control={formProps.control}
                                        name="customer_ids"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Select
                                                        {...field}
                                                        isMulti
                                                        options={activeInvoiceCustomers}
                                                        placeholder="Customer Name (optional)"
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#DBF3D8',
                                                                primary: '#92BAA3',
                                                            },
                                                        })}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="pt-4">
                            <div className="flex text-xl font-medium">
                                <AdjustmentsHorizontalIcon className="h-6 w-6 mr-2" />
                                Details
                            </div>
                            <div className="mt-4">
                                <PricingRuleDetailsForm formProps={formProps} invoiceTypes={invoiceTypes} invoiceUiOptions={invoiceUiOptions} ruleConditions={ruleConditions} />
                            </div>
                        </div>
                    </form>
                </Form>
            )}
        </>
    );
};

export default PricingRuleForm;

import React, { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogDescription } from '@/components/ui/dialog';
import { Form, FormField, FormItem, FormControl, FormMessage, FormLabel } from '@/components/common/form';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '@/components/ui/select';
import { createAdhocInvoiceInvoiceAdHocInvoicesPost } from '@/client/services.gen';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { Calendar } from '@/components/ui/calendar';

interface CustomerData {
    label: string;
    value: string;
}

interface InvoiceCreateProps {
    customerData: CustomerData[];
}


const InvoiceCreate: React.FC<InvoiceCreateProps> = ({ customerData }) => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const createInvoiceAdHocSchema = z.object({
        customerId: z.string({ required_error: 'Customer is required' }),
        startDate: z.date({ required_error: 'Start date is required' }),
        endDate: z.date({ required_error: 'End date is required' }),
    }).superRefine((data, ctx) => {
        if (!data.startDate || !data.endDate) return;
        if (data.startDate >= data.endDate) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Start date must be before end date',
                path: ['startDate'],
            });
        }
    });

    const form = useForm<z.infer<typeof createInvoiceAdHocSchema>>({
        resolver: zodResolver(createInvoiceAdHocSchema),
        defaultValues: {
            customerId: undefined,
            startDate: undefined,
            endDate: undefined
        }
    });

    const { handleSubmit, control } = form;

    const onSubmit = async (values: z.infer<typeof createInvoiceAdHocSchema>) => {
        setIsLoading(true);

        try {
            await createAdhocInvoiceInvoiceAdHocInvoicesPost({ body: { customer_id: values.customerId, start_date: values.startDate.toISOString(), end_date: values.endDate.toISOString() } }).then(() => {
                setIsLoading(false);
                setIsDialogOpen(false);
            });
        } catch (error) {
            console.error('Error creating invoice:', error);
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
                <Button onClick={() => setIsDialogOpen(true)} size="sm" className="ml-4 h-8 text-sm">
                    <Plus className="h-4 w-4 mr-2" />
                    Create Invoice
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Create Invoice</DialogTitle>
                    <DialogDescription></DialogDescription>
                </DialogHeader>
                <div className="text-rails-dark-blue">
                    <Form {...form}>
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
                            <FormField
                                control={control}
                                name="customerId"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Customer</FormLabel>
                                        <Select onValueChange={field.onChange}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select Customer" />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                {customerData.map((customer) => (
                                                    <SelectItem value={customer.value} key={customer.value}>{customer.label}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className="space-y-2">
                                <FormLabel>Invoice Period</FormLabel>
                                <div className="flex gap-4">
                                    <FormField
                                        control={form.control}
                                        name="startDate"
                                        render={({ field }) => (
                                            <FormItem className="flex-1">
                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <FormControl>
                                                            <Button
                                                                variant={"outline"}
                                                                className={cn(
                                                                    "w-full pl-3 text-left font-normal",
                                                                    !field.value && "text-muted-foreground"
                                                                )}
                                                            >
                                                                {field.value ? (
                                                                    format(field.value, "PPP")
                                                                ) : (
                                                                    <span>Start</span>
                                                                )}
                                                            </Button>
                                                        </FormControl>
                                                    </PopoverTrigger>
                                                    <PopoverContent className="w-auto p-0">
                                                        <Calendar
                                                            mode="single"
                                                            selected={field.value}
                                                            onSelect={field.onChange}
                                                            disabled={(date) =>
                                                                date > new Date() || date < new Date("2000-01-01")
                                                            }
                                                        />
                                                    </PopoverContent>
                                                </Popover>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="endDate"
                                        render={({ field }) => (
                                            <FormItem className="flex-1">
                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <FormControl>
                                                            <Button
                                                                variant={"outline"}
                                                                className={cn(
                                                                    "w-full pl-3 text-left font-normal",
                                                                    !field.value && "text-muted-foreground"
                                                                )}
                                                            >
                                                                {field.value ? (
                                                                    format(field.value, "PPP")
                                                                ) : (
                                                                    <span>End</span>
                                                                )}
                                                            </Button>
                                                        </FormControl>
                                                    </PopoverTrigger>
                                                    <PopoverContent className="w-auto p-0">
                                                        <Calendar
                                                            mode="single"
                                                            selected={field.value}
                                                            onSelect={field.onChange}
                                                            disabled={(date) =>
                                                                date > new Date() || date < new Date("2000-01-01")
                                                            }
                                                        />
                                                    </PopoverContent>
                                                </Popover>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                            <Button type="submit">{isLoading ? 'Creating' : 'Create'}</Button>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default InvoiceCreate;

import { useState, useEffect } from 'react';
import { storage } from '@/firebase';
import { ref, getDownloadURL } from 'firebase/storage';

const useStorage = (companyId: string) => {
    const [logoUrl, setLogoUrl] = useState<string>('');
    const [error, setError] = useState<string>();

    const fetchLogoPicture = async () => {
        try {
            const storageRef = ref(storage, `logo/${companyId}`);
            const url = await getDownloadURL(storageRef);
            return url;
        } catch (err: any) {
            setError(err.message);
            return null;
        }
    };

    useEffect(() => {
        if (companyId) {
            fetchLogoPicture().then((url) => {
                if (url) {
                    setLogoUrl(url);
                }
            });
        }
    }, [companyId]);

    return { logoUrl, error };
};

export default useStorage;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

import { DataTable } from '../common/datatable/dataTable.tsx';
import { DataTableColumnHeader } from '../common/datatable/dataTableColumnHeader.tsx';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../common/dropdown-menu.tsx';
import useInvoiceApi from '../../hooks/api/useInvoiceApi';

import CustomerView from './customerView';

const Customers = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [open, setOpen] = useState(false);
    const { getInvoiceCustomers } = useInvoiceApi();

    const fetchCustomers = () => {
        setIsLoading(true);
        getInvoiceCustomers().then((res) => {
            setTableData(res.data);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchCustomers();
    }, []);

    const columns = [
        {
            accessorKey: 'name',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
            cell: ({ row }) => (
                <div className="w-[200px] font-medium hover:underline">
                    <a href={`/customers/${row.original.id}`}>{row.getValue('name')}</a>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'actions',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Actions" />,
            cell: ({ row }) => (
                <DropdownMenu>
                    <DropdownMenuTrigger>
                        <EllipsisVerticalIcon className="h-6 w-6 mr-2" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                        <DropdownMenuItem>
                            <div
                                className="z-99 w-[80px] cursor-pointer"
                                onClick={() => {
                                    const selectedCustomerArr = tableData.filter((data) => data.id === row.original.id);
                                    setSelectedCustomer(selectedCustomerArr[0]);
                                    setOpen(true);
                                }}
                            >
                                Edit
                            </div>
                        </DropdownMenuItem>
                        {row.original.active && (
                            <DropdownMenuItem>
                                <a href={`/customers/dashboard/${row.original.id}/overview`}>View Dashboard</a>
                            </DropdownMenuItem>
                        )}
                    </DropdownMenuContent>
                </DropdownMenu>
            ),
            enableSorting: false,
            enableHiding: false,
        },
    ];

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Customers</h1>
                            <h2 className="text-xs text-rails-dark-blue">Manage customers for invoicing.</h2>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-20">
                                {tableData && (
                                    <DataTable
                                        loading={isLoading}
                                        data={tableData}
                                        columns={columns}
                                        // ActionButton={<CustomersCreate fetchCustomers={fetchCustomers} customerList={tableData} />}
                                        isMultiSelectRows={false}
                                        isDownloadable
                                        downloadData={tableData}
                                        tableName="customers"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <CustomerView open={open} setOpen={setOpen} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} customerList={tableData} />
        </div>
    );
};

export default Customers;

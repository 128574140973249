import * as React from 'react';
import { cn } from '../../lib/utils';

// Card Component
export const Card: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => (
    <div className={cn('rounded-lg border border-gray-200 bg-white shadow-sm', className)} {...props} />
);

// CardHeader Component
export const CardHeader: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => (
    <div className={cn('border-b border-gray-200 px-4 py-5 sm:px-6', className)} {...props} />
);

// CardTitle Component
export const CardTitle: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, ...props }) => (
    <h3 className={cn('text-lg leading-6 font-medium text-gray-900', className)} {...props} />
);

// CardDescription Component
export const CardDescription: React.FC<React.HTMLAttributes<HTMLParagraphElement>> = ({ className, ...props }) => (
    <p className={cn('mt-1 text-sm text-gray-500', className)} {...props} />
);

// CardContent Component
export const CardContent: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => <div className={cn('px-4 py-5 sm:p-6', className)} {...props} />;

// CardFooter Component
export const CardFooter: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => (
    <div className={cn('border-t border-gray-200 px-4 py-4 sm:px-6', className)} {...props} />
);

import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { ArrowDownTrayIcon, PaperAirplaneIcon } from '@heroicons/react/20/solid';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetFooter } from '@/components/ui/sheet';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Button } from '@/components/ui/button';
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog';
import { getInvoiceDetailInvoiceInvoiceDetailInvoiceIdGet, postInvoiceInvoicesQuickbooksPost, getInvoiceInvoicesDeliveryFileInvoiceIdGet } from '@/client/services.gen';

interface InvoiceDetailViewProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    invoiceData: any; // Replace 'any' with a more specific type if available
}

const invoiceColumns = [
    {
        accessorKey: 'charge_name',
        header: 'Charge in Rails',
        cell: ({ row }: { row: any }) => <div className="w-[100px] font-medium">{row.getValue('charge_name')}</div>,
    },
    {
        accessorKey: 'quickbooks_mapping',
        header: 'Charge in ERP',
        cell: ({ row }: { row: any }) => <div className="w-[100px] font-medium">{row.getValue('quickbooks_mapping')}</div>,
    },
    {
        accessorKey: 'quantity',
        header: 'Number of Charges',
        cell: ({ row }: { row: any }) => (
            <div className="flex flex-wrap space-x-2">
                <NumericFormat value={row.getValue('quantity')} displayType="text" thousandSeparator="," />
            </div>
        ),
    },
    {
        accessorKey: 'amount',
        header: 'Amount',
        cell: ({ row }: { row: any }) => (
            <div className="flex flex-wrap space-x-2">
                <span className="max-w-[500px] text-wrap">
                    <NumericFormat value={row.getValue('amount')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                </span>
            </div>
        ),
    },
];

const InvoiceDetailView: React.FC<InvoiceDetailViewProps> = ({ open, setOpen, invoiceData }) => {
    const [loading, setLoading] = useState(false);
    const [loadingQuickBooks, setLoadingQuickBooks] = useState(false);
    const [successUpload, setSuccessUpload] = useState(false);
    const navigate = useNavigate();

    const handleDownload = async () => {
        setLoading(true);
        await getInvoiceInvoicesDeliveryFileInvoiceIdGet({ path: { invoice_id: invoiceData.invoice_id } })
            .then((res) => {
                const tempLink = document.createElement('a');
                tempLink.href = res.data!.file_url;
                tempLink.setAttribute('download', `shippy_${invoiceData.invoice_id}_invoice_report.csv`);
                tempLink.click();
            })
            .finally(() => setLoading(false));
    };

    const handleUploadToQuickBooks = async () => {
        setLoadingQuickBooks(true);
        try {
            const response = await getInvoiceInvoicesDeliveryFileInvoiceIdGet({ path: { invoice_id: invoiceData.invoice_id } });
            const invoiceDetail = response.data!;
            if (invoiceDetail && invoiceDetail.file_url) {
                await postInvoiceInvoicesQuickbooksPost({ body: { invoice_id: invoiceData.invoice_id, file_url: invoiceDetail.file_url } });
                setSuccessUpload(true);
            } else {
                console.error('Failed to get invoice detail or file URL');
            }
        } catch (error) {
            console.error('Error uploading to QuickBooks:', error);
        } finally {
            setLoadingQuickBooks(false);
        }
    };

    const chargeDetails = invoiceData?.charges?.filter((charge: any) => charge.amount !== 0 && charge.amount !== '0.000000') || [];

    const canAddCharge = invoiceData?.status !== 'closed';

    const NavButton = canAddCharge ? (
        <Button className="p-4" onClick={() => navigate(`/invoices/${invoiceData.invoice_id}`)}>
            Manage Charges
        </Button>
    ) : null;

    return (
        invoiceData && (
            <div>
                <Sheet open={open} onOpenChange={setOpen}>
                    <SheetContent side="right" className="sm:max-w-2xl">
                        <SheetHeader className="pb-36 overflow-y-auto h-full">
                            <SheetTitle className="text-3xl">{invoiceData.customer_name}</SheetTitle>
                            <SheetDescription>{`Invoice ID: ${invoiceData.invoice_id}`}</SheetDescription>

                            <div className="pt-4">
                                <h2 className="text-md font-bold text-rails-dark-blue pl-2">Charge Details</h2>
                                <div>
                                    <div className="container mx-auto py-2 pl-2">
                                        {chargeDetails && <DataTable data={chargeDetails} columns={invoiceColumns} ActionButton={NavButton} showActionButtonInToolbar={true} />}
                                    </div>
                                </div>
                            </div>
                        </SheetHeader>
                        <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                            <div className="grid grid-cols-2 space-x-4">
                                <Button className={loading ? 'animate-pulse' : ''} onClick={() => handleDownload()}>
                                    <ArrowDownTrayIcon className="h-4 w-4 mr-2" />
                                    {loading ? 'Downloading' : 'Download'}
                                </Button>
                                <Button className={loadingQuickBooks ? 'animate-pulse' : ''} onClick={() => handleUploadToQuickBooks()}>
                                    <PaperAirplaneIcon className="h-4 w-4 mr-2" />
                                    {loadingQuickBooks ? 'Exporting' : 'Export'}
                                </Button>
                            </div>
                        </SheetFooter>
                    </SheetContent>
                </Sheet>

                <AlertDialog open={successUpload} onOpenChange={setSuccessUpload}>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Invoice Upload to QuickBooks Successful!</AlertDialogTitle>
                            <AlertDialogDescription>Your invoice has been uploaded successfully. Click Done to continue</AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel onClick={() => setSuccessUpload(false)}>Done</AlertDialogCancel>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </div>
        )
    );
};

export default InvoiceDetailView;

import { useState, useEffect } from 'react';
import {
    statsCarrierBillsStatsGet,
    postConfigCarrierReportExportPost,
    getConfigCarrierReportExportGet,
    tableDataDownloadCarrierBillsReportsChargeDetailJsonGet,
    postConfigCarrierReportExportEmailPost,
} from '@/client/services.gen';
import { type StatsCarrierBillsStatsGetResponse, Aggregation, Period, ColSelect, ColAggBy } from '@/client/types.gen';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { format, subWeeks } from 'date-fns';
import { Input } from '@/components/ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { BarChartCard } from '@/components/ui/charts/bar';

const ftpFormSchema = z.object({
    host: z
        .string()
        .min(1, 'Host is required')
        .refine((value) => /^(\d{1,3}\.){3}\d{1,3}$|^[a-zA-Z0-9.-]+$/.test(value), {
            message: 'Enter a valid IP address or hostname',
        }),
    port: z.number().int().min(1).max(65535),
    username: z.string().min(1, 'Username is required'),
    password: z.string().min(1, 'Password is required'),
    directory: z
        .string()
        .min(1, 'Directory is required')
        .refine((value) => /^(\/[^/ ]*)+\/?$/.test(value), {
            message: 'Please enter a valid Unix-style directory path',
        }),
});

type FtpFormValues = z.infer<typeof ftpFormSchema>;

export default function CarrierSpend() {
    const [stats, setStats] = useState<StatsCarrierBillsStatsGetResponse>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [downloadStartDate, setDownloadStartDate] = useState<Date>(subWeeks(new Date(), 1));
    const [downloadEndDate, setDownloadEndDate] = useState<Date>(new Date());
    const [emailId, setEmailId] = useState<string>('');

    const form = useForm<FtpFormValues>({
        resolver: zodResolver(ftpFormSchema),
        defaultValues: {
            host: '',
            port: 22,
            username: '',
            password: '',
            directory: '',
        },
    });

    const handleFetchStats = async (params: any) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await statsCarrierBillsStatsGet({
                query: {
                    col_select: params.col_select || 'CHARGE_AMOUNT',
                    col_agg_by: params.col_agg_by || 'CARRIER_NAME',
                    type_agg: params.type_agg || 'sum',
                    type_date: params.type_date || 'week',
                    start_date: params.start_date || subWeeks(new Date(), 6).toISOString(),
                    end_date: params.end_date || new Date().toISOString(),
                }
            });
            setStats(response.data);
        } catch (error) {
            console.error('Error fetching stats:', error);
            setError('Failed to fetch stats. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchFtpConfigs = async () => {
        await getConfigCarrierReportExportGet();
    };

    useEffect(() => {
        handleFetchStats({
            col_select: 'CHARGE_AMOUNT',
            col_agg_by: 'CARRIER_NAME',
            type_agg: 'sum',
            type_date: 'week',
            start_date: subWeeks(new Date(), 6).toISOString(),
            end_date: new Date().toISOString(),
        });
    }, []);

    const onSubmit = async (values: FtpFormValues) => {
        setIsSubmitting(true);
        setSuccessMessage(null);
        setError(null);
        try {
            postConfigCarrierReportExportPost({
                body: {
                    host: values.host,
                    port: values.port,
                    username: values.username,
                    password: values.password,
                    dir: values.directory,
                },
            });

            setSuccessMessage('FTP configuration has been saved successfully.');
            await fetchFtpConfigs();
        } catch (error) {
            setError('Failed to save FTP configuration. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSuccessMessage(null);
        setError(null);
        try {
            postConfigCarrierReportExportEmailPost({ body: { email: emailId } });
            setSuccessMessage('Email configuration has been saved successfully.');
        } catch (error) {
            setError('Failed to save email configuration. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDownload = async () => {
        setIsLoading(true);
        setError(null);
        const response = await tableDataDownloadCarrierBillsReportsChargeDetailJsonGet({
            query: {
                start_date: downloadStartDate.toISOString(),
                end_date: downloadEndDate.toISOString(),
            },
        });

        // Create a Blob from the response
        const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });

        // Create a link element and trigger the download
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'carrier_bill_report.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setSuccessMessage('Download successful.');
        }
        setIsLoading(false);
    };

    return (
        <div className="p-4">
            <div className="flex justify-end">
                <Dialog>
                    <DialogTrigger asChild>
                        <Button>
                            <div className="pr-2">
                                <ArrowDownTrayIcon className="w-4 h-4" />
                            </div>
                            <div>Export</div>
                        </Button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Export Reports</DialogTitle>
                        </DialogHeader>
                        <Tabs defaultValue="download">
                            <TabsList className="grid w-full grid-cols-3">
                                <TabsTrigger value="download" className="w-full">
                                    Download
                                </TabsTrigger>
                                <TabsTrigger value="email" className="w-full">
                                    Schedule Email
                                </TabsTrigger>
                                <TabsTrigger value="ftp" className="w-full">
                                    SFTP Dump
                                </TabsTrigger>
                            </TabsList>
                            <TabsContent value="download">
                                <form className="space-y-4">
                                    <div className="flex space-x-4">
                                        <div className="space-y-2">
                                            <label className="block text-sm font-medium text-gray-700">Start Date</label>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant="outline">{format(downloadStartDate, 'PP')}</Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={downloadStartDate} onSelect={(date) => date && setDownloadStartDate(date)} />
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                        <div className="space-y-2">
                                            <label className="block text-sm font-medium text-gray-700">End Date</label>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant="outline">{format(downloadEndDate, 'PP')}</Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={downloadEndDate} onSelect={(date) => date && setDownloadEndDate(date)} />
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                    </div>
                                    <Button onClick={handleDownload} disabled={isLoading}>
                                        {isLoading ? 'Downloading...' : 'Download'}
                                    </Button>
                                    {successMessage && <p className="text-green-500">{successMessage}</p>}
                                    {error && <p className="text-red-500">{error}</p>}
                                </form>
                            </TabsContent>
                            <TabsContent value="email">
                                <form onSubmit={handleEmailSubmit} className="space-y-4">
                                    <div className="space-y-2">
                                        <label htmlFor="emailId" className="block text-sm font-medium text-gray-700">
                                            Email ID
                                        </label>
                                        <Input id="emailId" type="email" placeholder="Enter your email" value={emailId} onChange={(e) => setEmailId(e.target.value)} />
                                    </div>
                                    <Button type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Submitting...' : 'Schedule Email'}
                                    </Button>
                                    {successMessage && <p className="text-green-500">{successMessage}</p>}
                                    {error && <p className="text-red-500">{error}</p>}
                                </form>
                            </TabsContent>
                            <TabsContent value="ftp">
                                <Form {...form}>
                                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                                        <FormField
                                            control={form.control}
                                            name="host"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Host</FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Enter FTP host" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="port"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Port</FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            type="number"
                                                            placeholder="Enter FTP port"
                                                            {...field}
                                                            onChange={(e) => field.onChange(parseInt(e.target.value, 10))}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="username"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Username</FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Enter FTP username" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="password"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Password</FormLabel>
                                                    <FormControl>
                                                        <Input type="password" placeholder="Enter FTP password" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="directory"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Directory</FormLabel>
                                                    <FormControl>
                                                        <Input placeholder="Enter FTP directory" {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <Button type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? <div className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent"></div> : null}
                                            {isSubmitting ? 'Submitting...' : 'Set up FTP Export'}
                                        </Button>
                                        {successMessage && <p className="text-green-500">{successMessage}</p>}
                                        {error && <p className="text-red-500">{error}</p>}
                                    </form>
                                </Form>
                            </TabsContent>
                        </Tabs>
                    </DialogContent>
                </Dialog>
            </div>
            <BarChartCard
                title="Carrier Spend and Utilization"
                description="Analyze carrier costs and usage"
                data={stats || []}
                dataKeys={[]}
                xAxisDataKey="date"
                onFetchData={handleFetchStats}
                isLoading={isLoading}
                error={error}
                aggregateOptions={Object.fromEntries(Object.entries(ColAggBy).map(([key, value]) => [value, key.replace(/_/g, ' ')]))}
                columnOptions={Object.fromEntries(Object.entries(ColSelect).map(([key, value]) => [value, key.replace(/_/g, ' ')]))}
                aggregationTypeOptions={Object.fromEntries(Object.entries(Aggregation).map(([key, value]) => [value, key]))}
                dateTypeOptions={Object.fromEntries(Object.entries(Period).map(([key, value]) => [value, key]))}
                initialParams={{
                    col_select: 'CHARGE_AMOUNT',
                    col_agg_by: 'CARRIER_NAME',
                    type_agg: 'sum',
                    type_date: 'week',
                    start_date: subWeeks(new Date(), 6).toISOString(),
                    end_date: new Date().toISOString(),
                }}
            />
        </div>
    );
}

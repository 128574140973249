import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetFooter, SheetTitle } from '../../common/sheet.tsx';
import { Input } from '../../common/input.tsx';

import LocationEdit from './locationEdit';
import useStorageApi from '../../../hooks/api/useStorageApi';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '../../common/alert-dialog.tsx';
import { Button } from '@/components/ui/button';

const LocationView = ({ open, setOpen, selectedLocation, fetchLocations }) => {
    const { deleteLocations } = useStorageApi();

    const onDelete = (locationId) => {
        deleteLocations(locationId).then(() => {
            setOpen(false);
            fetchLocations();
        });
    };

    return (
        selectedLocation && (
            <Sheet open={open} onOpenChange={setOpen}>
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">{selectedLocation && `${selectedLocation.name}`}</SheetTitle>
                        <SheetDescription>View location details below</SheetDescription>
                        <div className="pt-8">
                            <span className="flex text-xl font-medium">Warehouse</span>
                            <Input className="bg-white w-72 mt-2" type="text" placeholder={selectedLocation?.warehouse?.name} disabled />
                        </div>
                        <div className="pt-8">
                            <span className="flex text-xl font-medium">Storage Type</span>
                            <Input className="bg-white w-72 mt-2" type="text" placeholder={selectedLocation?.storage_type?.name} disabled />
                        </div>
                        <div className="pt-8">
                            <span className="flex text-xl font-medium">Length</span>
                            <Input className="bg-white w-72 mt-2" type="text" placeholder={selectedLocation?.storage_type?.dimensions?.length} disabled />
                        </div>
                        <div className="pt-4">
                            <span className="flex text-xl font-medium">Width</span>
                            <Input className="bg-white w-72 mt-2" type="text" placeholder={selectedLocation?.storage_type?.dimensions?.width} disabled />
                        </div>
                        <div className="pt-4">
                            <span className="flex text-xl font-medium">Height</span>
                            <Input className="bg-white w-72 mt-2" type="text" placeholder={selectedLocation?.storage_type?.dimensions?.height} disabled />
                        </div>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <AlertDialog>
                                <AlertDialogTrigger asChild>
                                    <Button variant="outline">Delete</Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Are you sure you want to delete {selectedLocation.name}?</AlertDialogTitle>
                                        <AlertDialogDescription>This action cannot be undone. This will permanently delete the location.</AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction onClick={() => onDelete(selectedLocation.id)}>Delete</AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                            <LocationEdit selectedLocation={selectedLocation} fetchLocations={fetchLocations} setOpen={setOpen} />
                        </div>
                    </SheetFooter>
                </SheetContent>
            </Sheet>
        )
    );
};

export default LocationView;

import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import type { ColumnDef, SortingState, VisibilityState } from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../table';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { DataTableToolbar } from './data-table-toolbar';
import { DataTablePagination } from './data-table-pagination';
import { Checkbox } from '@/components/ui/checkbox';
import LoadingSpinner from '@/components/ui/loading-spinner';
import DownloadButton from './data-table-download';

interface DataTableProps<TData, TValue> {
    loading: boolean;
    data: TData[];
    columns: ColumnDef<TData, TValue>[];
    toolbarColumnFilters?: any;
    rowOnClick?: any;
    ActionButton?: any;
    isMultiSelectRows?: any;
    MultiSelectRowsAction?: any;
    setRowsSelected?: any;
    isDownloadable?: any;
    downloadData?: any;
    tableName?: any;
    showPagination?: boolean;
    showToolBar?: boolean;
    collapsible?: boolean;
    collapsibleContent?: React.FC<{ row: any }>;
    showActionButtonInToolbar?: boolean;
    showActionButtonInCollapsible?: boolean;
}

export function DataTable<TData, TValue>({
    loading,
    data,
    columns,
    toolbarColumnFilters,
    rowOnClick,
    ActionButton,
    isMultiSelectRows,
    MultiSelectRowsAction,
    setRowsSelected,
    isDownloadable,
    downloadData,
    tableName,
    showPagination = true,
    showToolBar = true,
    collapsible = false,
    collapsibleContent = ({}) => <></>,
    showActionButtonInToolbar = true,
    showActionButtonInCollapsible = false,
}: DataTableProps<TData, TValue>) {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [globalFilter, setGlobalFilter] = React.useState('');
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
    const [rowSelection, setRowSelection] = React.useState({});

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: showPagination ? getPaginationRowModel() : undefined,
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        enableRowSelection: isMultiSelectRows,
        onRowSelectionChange: setRowSelection,
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        state: {
            sorting,
            globalFilter,
            columnVisibility,
            rowSelection,
        },
    });

    if (isMultiSelectRows) {
        columns.unshift({
            id: 'select',
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value: boolean | 'indeterminate') => table.toggleAllPageRowsSelected(!!value)}
                    aria-label="Select all"
                    className="translate-y-[2px]"
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value: boolean | 'indeterminate') => row.toggleSelected(!!value)}
                    aria-label="Select row"
                    className="translate-y-[2px]"
                />
            ),
            enableSorting: false,
            enableHiding: false,
        });
    }

    const rowsSelected = table.getSelectedRowModel();
    React.useEffect(() => {
        if (isMultiSelectRows) {
            setRowsSelected(Object.keys(rowsSelected.rowsById));
        }
    }, [rowsSelected.rows.length]);

    return (
        <div>
            <LoadingSpinner loading={loading} />
            {!loading && (
                <>
                    {showToolBar && (
                        <div className="pb-4">
                            <DataTableToolbar
                                table={table}
                                toolbarColumnFilters={toolbarColumnFilters}
                                globalFilter={globalFilter}
                                ActionButton={showActionButtonInToolbar ? ActionButton : null}
                                MultiSelectRowsAction={MultiSelectRowsAction}
                            />
                        </div>
                    )}
                    <div className="rounded-md border">
                        <Table>
                            <TableHeader>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <TableRow key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <TableHead key={header.id}>
                                                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                                </TableHead>
                                            );
                                        })}
                                        {collapsible && <TableHead></TableHead>}
                                    </TableRow>
                                ))}
                            </TableHeader>
                            <TableBody>
                                {table.getRowModel().rows?.length ? (
                                    table.getRowModel().rows.map((row) => {
                                        const tableRow = (
                                            <TableRow
                                                key={row.id}
                                                data-state={row.getIsSelected() && 'selected'}
                                                onClick={() => {
                                                    if (rowOnClick) {
                                                        rowOnClick(row);
                                                    }
                                                }}
                                            >
                                                {row.getVisibleCells().map((cell) => (
                                                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                                                ))}
                                                {collapsible && (
                                                    <TableCell>
                                                        <div className="flex flex-wrap space-x-2">
                                                            <span className="max-w-[500px] text-wrap">
                                                                <CollapsibleTrigger>
                                                                    <ChevronDownIcon className="h-6 w-6 mr-2" />
                                                                </CollapsibleTrigger>
                                                            </span>
                                                        </div>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        );

                                        if (collapsible) {
                                            const RowCollapsibleContent = collapsibleContent;
                                            const totalColumns = row.getVisibleCells().length + 1; // add one for the collapsible column
                                            return (
                                                <Collapsible key={row.id} asChild>
                                                    <>
                                                        {tableRow}
                                                        <TableRow>
                                                            <CollapsibleContent asChild>
                                                                <td colSpan={totalColumns}>
                                                                    <div className="mx-5 my-5">
                                                                        {showActionButtonInCollapsible && <div className="flex justify-end pb-2">{ActionButton}</div>}
                                                                        <RowCollapsibleContent row={row.original} />
                                                                    </div>
                                                                </td>
                                                            </CollapsibleContent>
                                                        </TableRow>
                                                    </>
                                                </Collapsible>
                                            );
                                        }

                                        return tableRow;
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} className="h-24 text-center">
                                            No results.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <div className="pt-4 flex justify-between items-center">
                        <div>{showPagination && <DataTablePagination table={table} isMultiSelectRows={isMultiSelectRows} />}</div>
                        <div>{isDownloadable && <DownloadButton jsonData={downloadData} tableName={tableName} />}</div>
                    </div>
                </>
            )}
        </div>
    );
}

/* eslint-disable no-nested-ternary */
import { filterSymbolsHumanReadable, PricingMethodCol, TierConditionCol, PricingMethodMarginCol, PricingMethodVariableCol } from '../consts/vars';

const getInvoiceRuleDisplayName = (rawName) => {
    if (rawName === '_global') {
        return 'Global';
    }
    if (rawName === null) {
        return 'Unnamed';
    }
    return rawName;
};

const transformCustomerList = (ruleCustomerList, dbCustomerList) => {
    let finalCustomerList = [];
    const filteredCustomerData = dbCustomerList.filter((obj) => ruleCustomerList.includes(obj.id));

    finalCustomerList = filteredCustomerData.map((customer) => ({
        label: customer.name,
        value: customer.id,
    }));

    return finalCustomerList;
};

const transformRule = (rule, ruleConditionMetadata, customerList) => {
    // Create a copy of the rule to transform
    const newRule = { ...rule };

    // Filter the customer list based on customer IDs
    const filteredCustomerData = customerList.data.filter((obj) => newRule.customer_ids.includes(obj.id));

    // Map the filtered customer data to include label and value
    newRule.customer_ids = filteredCustomerData.map((customer) => ({
        label: customer.name,
        value: customer.id,
    }));

    // Map charge_type to include label and value
    newRule.charge_type = {
        label: newRule.charge_type,
        value: newRule.charge_type_id,
    };

    // Transform rule conditions
    newRule.rule_conditions = rule.rule_conditions?.map((condition) => {
        // Safely access ruleConditionMetadata[condition.col] and filterOptions
        const conditionMetadata = ruleConditionMetadata[condition.col] || {};
        const filterOptions = conditionMetadata.filterOptions || [];

        // Find the correct filter based on condition.filter
        const conditionFilter = filterOptions.find((option) => option.value === condition.filter);

        // Determine the correct condition value
        let conditionValue;
        if (typeof condition.value === 'string' && condition.value.includes(',')) {
            const condValueArray = condition.value.split(',');
            conditionValue = conditionMetadata.valueOptions?.filter((option) => condValueArray.includes(option.value));
        } else if (!conditionMetadata.valueOptions) {
            conditionValue = condition.value.toString(); // Convert to string if necessary
        } else {
            conditionValue = conditionMetadata.valueOptions.find((option) => option.value === condition.value.toString()); // Convert to string if necessary
        }

        // Return the transformed condition
        return {
            col: { label: conditionMetadata.displayName || condition.col, value: condition.col },
            filter: conditionFilter || {},
            value: conditionValue || {},
        };
    });

    // Transform tiers in the rule
    newRule.tiers = rule.tiers?.map((tier) => {
        // Create a copy of the tier
        const newRuleTier = { ...tier };

        // Initialize tier conditions
        newRuleTier.tier_conditions = {};

        // Transform pricing choices filter and map pricing choices
        newRuleTier.pricing_choices_filter = tier.pricing_choices_filter;
        newRuleTier.pricing_choices = tier.pricing_choices?.map((pricingChoice) => {
            const newPricingChoice = { ...pricingChoice };
            newPricingChoice.value = pricingChoice.value.toString();
            newPricingChoice.col = pricingChoice.col || '';
            newPricingChoice.method = pricingChoice.method;

            return newPricingChoice;
        });

        // Transform tier conditions
        if (Array.isArray(tier.tier_conditions)) {
            const startCond = tier.tier_conditions.filter((cond) => cond.filter === '>=');
            if (startCond.length > 0) {
                newRuleTier.tier_conditions.rangeCol = startCond[0].col;
                newRuleTier.tier_conditions.rangeStart = startCond[0].value;
            }

            const endCond = tier.tier_conditions.filter((cond) => cond.filter === '<=');
            if (endCond.length > 0) {
                newRuleTier.tier_conditions.rangeEnd = endCond[0].value;
            }
        }

        return newRuleTier;
    });

    // Return the transformed rule
    return newRule;
};

const transformRuleConditionOptions = (ruleConditions) => {
    const conditionOptions = ruleConditions.map((data) => ({ label: data.name, value: data.col }));
    const conditionMetadataArr = ruleConditions.map((data) => {
        const allowedFilterOptions = data.allowed_filters.map((filter) => ({ label: filterSymbolsHumanReadable[filter], value: filter }));
        let allowedValueOptions;
        if (data.allowed_values) {
            allowedValueOptions = Object.keys(data.allowed_values).map((val) => ({ label: data.allowed_values[val], value: val }));
        }

        return { [data.col]: { filterOptions: allowedFilterOptions, valueOptions: allowedValueOptions, displayName: data.name } };
    });

    // convert array of objs into obj
    const conditionMetadata = Object.assign({}, ...conditionMetadataArr);

    return { conditionOptions, conditionMetadata };
};

const describeTiers = (tiers, tiersAreCumulative) => {
    if (!tiers || tiers.length === 0) {
        // If there are no tiers, return an appropriate message
        return 'No tiers defined.';
    }

    // Initialize an array to hold the tier descriptions
    const tierDescriptions = [];

    // Iterate through each tier in the tiers array
    tiers.forEach((tier, index) => {
        // Initialize an array to hold the descriptions for this tier
        const tierDescriptionParts = [];

        // Describe pricing choices if available
        if (tier.pricing_choices && tier.pricing_choices.length > 0) {
            const pricingChoicesDescriptions = tier.pricing_choices.map((choice) => {
                const method = PricingMethodCol[choice.method];
                const { value } = choice;

                if (choice.method.includes('margin')) {
                    return `${value} ${method} ${`on ${PricingMethodMarginCol[choice.col]}`}`;
                }
                if (choice.method.includes('fixed')) {
                    return `${method} of $${value}`;
                }
                if (choice.method.includes('variable')) {
                    return `${method} ${PricingMethodVariableCol[choice.col]} charge of $${value}`;
                }
                return '';
            });

            // Combine the descriptions into a sentence
            const pricingChoicesDescriptionString = pricingChoicesDescriptions.join(', ');

            // Handle pricing choices filter for multiple choices
            if (tier.pricing_choices.length > 1) {
                tierDescriptionParts.push(`Taking ${tier.pricing_choices_filter} of ${pricingChoicesDescriptionString}`);
            } else {
                tierDescriptionParts.push(pricingChoicesDescriptionString);
            }
        }

        // Describe tier conditions if available
        if (tier.tier_conditions) {
            const { rangeCol, rangeStart, rangeEnd } = tier.tier_conditions;

            // Add range condition description
            if (rangeCol) {
                const rangeColLabel = TierConditionCol[rangeCol] || '';
                tierDescriptionParts.push(`if ${rangeColLabel} from ${rangeStart || 'N/A'}`);
            }

            // Add range end description or 'onwards' if rangeEnd is not specified
            if (rangeEnd) {
                tierDescriptionParts.push(`to ${rangeEnd}`);
            } else if (rangeCol && !rangeEnd) {
                tierDescriptionParts.push('and beyond');
            }
        }

        // Combine the tier description parts and add them to the tierDescriptions array
        tierDescriptions.push(tierDescriptionParts.join(' '));
    });

    // Create the final sentence, joining tier descriptions with the correct conjunction
    const conjunction = tiersAreCumulative ? ' or ' : ' and ';
    const finalDescription = tierDescriptions.join(conjunction);

    // Return the final description
    return finalDescription;
};

const transformPricingCategories = (pricingCategories, ruleData) =>
    // Iterate over each pricing category
    pricingCategories.map((category) => {
        const matchedRules = ruleData.filter((rule) => rule.charge_type_id === category.id);

        // Create a new category object with the transformed rules
        return {
            ...category,
            // Add the new rules field with the list of transformed rules
            rules: matchedRules,
        };
    });

export { getInvoiceRuleDisplayName, transformRule, transformRuleConditionOptions, transformCustomerList, describeTiers, transformPricingCategories };

import React from 'react';
import { Loader2 } from 'lucide-react';

interface LoadingSpinnerProps {
    className?: string;
    loading: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ className = '', loading }) =>
    loading ? <Loader2 className={className ? `${className} animate-spin` : 'h-6 w-6 animate-spin'} /> : null;

export default LoadingSpinner;

/* eslint-disable no-nested-ternary */
import { UsersIcon, AdjustmentsHorizontalIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { getInvoiceRuleDisplayName } from '../../../utils/invoice.jsx';
import { ScrollArea } from '../../common/scroll-area.tsx';
import { Separator } from '../../common/separator.tsx';

import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetFooter } from '../../common/sheet.tsx';
import { Table, TableHeader, TableHead, TableBody, TableRow, TableCell } from '../../common/table.tsx';

import { Button } from '@/components/ui/button';

import { Switch } from '../../common/switch.tsx';
import { Badge } from '../../common/badge.tsx';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '../../common/alert-dialog.tsx';
import { Card, CardContent, CardHeader, CardTitle } from '../../common/card.tsx';
import { Input } from '../../common/input.tsx';

import useInvoiceApi from '@/hooks/api/useInvoiceApi';
import PricingRuleEdit from './pricingRuleEdit.jsx';
import { TierConditionCol, PricingMethodVariableCol, PricingMethodCol, PricingMethodMarginCol } from '../../../consts/vars.js';
import { Select as RegSelect, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../../common/select.tsx';

const PricingRuleView = ({ open, setOpen, pricingRule, setPricingRule, ruleConditionMetadata }) => {
    const { deleteRule } = useInvoiceApi();
    const onDelete = (pricingRuleId) => {
        deleteRule(pricingRuleId).then(() => {
            setOpen(false);
            window.location.reload();
        });
    };

    return (
        pricingRule && (
            <Sheet open={open} onOpenChange={setOpen}>
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">{pricingRule && `${getInvoiceRuleDisplayName(pricingRule.name)}`}</SheetTitle>
                        <SheetDescription>View pricing rule details below</SheetDescription>

                        <div className="pt-4">
                            <div className="flex text-xl font-medium">
                                <GlobeAltIcon className="h-6 w-6 mr-2" />
                                Default
                            </div>
                            <div className="mt-2 flex items-center space-x-4">
                                <Switch checked={pricingRule.default} disabled />
                            </div>
                        </div>

                        <div className="pt-8">
                            <div className="flex text-xl font-medium">
                                <UsersIcon className="h-6 w-6 mr-2" />
                                Customers
                            </div>
                            <ScrollArea className="overflow-y-auto max-h-72 rounded-md border px-4 mt-2 pb-2">
                                <div className="grid grid-rows-5 pt-2" />
                                {pricingRule.default && <div className="w-100 pr-2">This is a default rule, applies to all customers.</div>}
                                {!pricingRule.customer_ids && !pricingRule.default && <div className="w-100 pr-2">No assigned customers.</div>}
                                {pricingRule.customer_ids &&
                                    pricingRule.customer_ids.map((customer, idx) => (
                                        <>
                                            <div className="w-100 pr-2">{customer.label}</div>
                                            {idx !== pricingRule.customer_ids.length - 1 && <Separator className="my-2" />}
                                        </>
                                    ))}
                            </ScrollArea>
                        </div>
                        <div className="pt-10">
                            <div className="flex text-xl font-medium">
                                <AdjustmentsHorizontalIcon className="h-6 w-6 mr-2" />
                                Details
                            </div>
                            <div>
                                <div className="mt-4 mb-2">
                                    {!pricingRule.rule_conditions && <span className="text-base text-gray-500">No conditions.</span>}
                                    {pricingRule.rule_conditions && <span className="text-base text-gray-500">Conditions</span>}
                                </div>
                            </div>
                            {pricingRule.rule_conditions && (
                                <ScrollArea className="overflow-y-auto overflow-x-auto max-h-72 rounded-md border mt-2">
                                    <div>
                                        <Table className="w-full">
                                            <TableHeader className="bg-slate-50">
                                                <TableRow>
                                                    <TableHead>Condition</TableHead>
                                                    <TableHead>Filter</TableHead>
                                                    <TableHead>Value</TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {pricingRule.rule_conditions.map((ruleCondition) => (
                                                    <TableRow>
                                                        <TableCell className="pr-2 w-1/3">
                                                            <Badge variant="outline">
                                                                {ruleCondition.col.label.replace('num_', '').replace('shipping_', '').replaceAll('_', ' ')}
                                                            </Badge>
                                                        </TableCell>
                                                        <TableCell className="pr-2 w-1/3">
                                                            <Badge>{ruleCondition.filter.label}</Badge>
                                                        </TableCell>
                                                        <TableCell className="pr-2 w-1/3">
                                                            {ruleCondition.value &&
                                                                ruleCondition.value.constructor.name === 'Array' &&
                                                                ruleCondition.value.map((val) => (
                                                                    <Badge className="mt-2" variant="outline">
                                                                        {val.label}
                                                                    </Badge>
                                                                ))}
                                                            {ruleCondition.value && ruleCondition.value.constructor.name !== 'Array' && (
                                                                <Badge variant="outline">
                                                                    {typeof ruleCondition.value === 'string' && ruleCondition.value}
                                                                    {ruleCondition.value.constructor.name === 'Object' && ruleCondition.value.label}
                                                                </Badge>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </ScrollArea>
                            )}
                            <div className="mt-12">
                                <span className="text-base text-gray-500">Pricing Type</span>
                                <Input className="bg-white w-72 mt-2" type="text" placeholder={pricingRule.charge_type.label} disabled />
                            </div>
                        </div>
                        <div className="pt-10">
                            <div className="grid grid-rows-2">
                                <span className="text-base text-gray-500">Pricing Definition</span>
                                <div className="flex items-center grid grid-cols-5 pt-1 pb-2">
                                    <div className="flex text-md col-span-4 whitespace-nowrap text-wrap">Should charges from each tier be summed up?</div>
                                    <div className="flex col-span-1 justify-end">
                                        <Switch checked={pricingRule.tiers_are_cumulative} disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2">
                                {pricingRule.tiers &&
                                    pricingRule.tiers.map((tier, tierIdx) => (
                                        <Card key={tierIdx} className="bg-gray-100 border-transparent shadow-none mb-2">
                                            <CardHeader>
                                                <CardTitle>
                                                    <div className="flow-root">
                                                        <div className="float-left text-gray-700">Tier {tierIdx + 1}</div>
                                                    </div>
                                                </CardTitle>
                                            </CardHeader>
                                            <CardContent>
                                                {Object.keys(tier.tier_conditions || {}).length > 0 && (
                                                    <div className="mb-4 flex text-base">
                                                        <div className="mt-2 mr-2 vertical-align">
                                                            <span className="w-content">Tier defined as</span>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <RegSelect
                                                                className="bg-white whitespace-nowrap w-fit"
                                                                type="number"
                                                                defaultValue={TierConditionCol[tier.tier_conditions.rangeCol]}
                                                                value={TierConditionCol[tier.tier_conditions.rangeCol]}
                                                                disabled
                                                            >
                                                                <SelectTrigger className="bg-white w-fit">
                                                                    <SelectValue />
                                                                </SelectTrigger>
                                                                <SelectContent>
                                                                    <SelectGroup>
                                                                        <SelectItem value={TierConditionCol[tier.tier_conditions.rangeCol]}>
                                                                            {TierConditionCol[tier.tier_conditions.rangeCol]}
                                                                        </SelectItem>
                                                                    </SelectGroup>
                                                                </SelectContent>
                                                            </RegSelect>
                                                            <div className="mx-2">between</div>
                                                            <Input className="bg-white w-20 text-center" type="text" placeholder={tier.tier_conditions.rangeStart} disabled />
                                                            <div className="mx-2">and</div>
                                                            <Input className="bg-white w-20 text-center" type="text" placeholder={tier.tier_conditions.rangeEnd} disabled />
                                                        </div>
                                                    </div>
                                                )}
                                                {tier.pricing_choices_filter && (
                                                    <div className="flex items-center text-base mb-2">
                                                        <span className="mr-2">Pricing defined as</span>
                                                        <div className="bg-white border rounded-md p-2">{tier.pricing_choices_filter} of</div>
                                                    </div>
                                                )}
                                                <div className="flex flex-col space-y-2">
                                                    {tier.pricing_choices &&
                                                        tier.pricing_choices.map((pricingChoice, pricingChoiceIdx) => (
                                                            <div key={pricingChoiceIdx} className="flex items-center space-x-2">
                                                                <RegSelect
                                                                    className="bg-white whitespace-nowrap w-fit"
                                                                    type="number"
                                                                    defaultValue={PricingMethodCol[pricingChoice.method]}
                                                                    value={PricingMethodCol[pricingChoice.method]}
                                                                    disabled
                                                                >
                                                                    <SelectTrigger className="bg-white whitespace-nowrap w-fit">
                                                                        <SelectValue />
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        <SelectGroup>
                                                                            <SelectItem value={PricingMethodCol[pricingChoice.method]}>
                                                                                {PricingMethodCol[pricingChoice.method]}
                                                                            </SelectItem>
                                                                        </SelectGroup>
                                                                    </SelectContent>
                                                                </RegSelect>
                                                                {!pricingChoice.method.includes('fixed') && (
                                                                    <div className="flex items-center">
                                                                        {pricingChoice.method.includes('margin') && (
                                                                            <div className="ml-2 vertical-align">
                                                                                <span className="mr-2"> on </span>
                                                                            </div>
                                                                        )}
                                                                        <RegSelect
                                                                            className="bg-white whitespace-nowrap w-fit"
                                                                            type="number"
                                                                            defaultValue={
                                                                                pricingChoice.method.includes('margin')
                                                                                    ? PricingMethodMarginCol[pricingChoice.col]
                                                                                    : PricingMethodVariableCol[pricingChoice.col]
                                                                            }
                                                                            value={
                                                                                pricingChoice.method.includes('margin')
                                                                                    ? PricingMethodMarginCol[pricingChoice.col]
                                                                                    : PricingMethodVariableCol[pricingChoice.col]
                                                                            }
                                                                            disabled
                                                                        >
                                                                            <SelectTrigger className="bg-white w-fit">
                                                                                <SelectValue />
                                                                            </SelectTrigger>
                                                                            <SelectContent>
                                                                                <SelectGroup>
                                                                                    <SelectItem
                                                                                        value={
                                                                                            pricingChoice.method.includes('margin')
                                                                                                ? PricingMethodMarginCol[pricingChoice.col]
                                                                                                : PricingMethodVariableCol[pricingChoice.col]
                                                                                        }
                                                                                    >
                                                                                        {pricingChoice.method.includes('margin')
                                                                                            ? PricingMethodMarginCol[pricingChoice.col]
                                                                                            : PricingMethodVariableCol[pricingChoice.col]}
                                                                                    </SelectItem>
                                                                                </SelectGroup>
                                                                            </SelectContent>
                                                                        </RegSelect>
                                                                    </div>
                                                                )}
                                                                <div className="ml-2">
                                                                    <Input
                                                                        className="bg-white w-20"
                                                                        type="number"
                                                                        placeholder={pricingChoice.value}
                                                                        specialType={
                                                                            pricingChoice.method == null ? '' : pricingChoice.method.includes('percent') ? 'percent' : 'dollar'
                                                                        }
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </CardContent>
                                        </Card>
                                    ))}
                            </div>
                        </div>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <AlertDialog>
                                <AlertDialogTrigger asChild>
                                    <Button variant="outline">Delete</Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Are you sure you want to delete {pricingRule.name}?</AlertDialogTitle>
                                        <AlertDialogDescription>This action cannot be undone. This will permanently delete the rule.</AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction onClick={() => onDelete(pricingRule.id)}>Delete</AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                            <PricingRuleEdit pricingRule={pricingRule} setPricingRule={setPricingRule} ruleConditionMetadata={ruleConditionMetadata} />
                        </div>
                    </SheetFooter>
                </SheetContent>
            </Sheet>
        )
    );
};

export default PricingRuleView;

import useFetch from '../useFetch';
import { backendUri } from '../../consts/vars';
import { getRequestHeader } from '../../utils/auth';

const useStorageApi = () => {
    const { makeApiCall } = useFetch();

    const getStorageTypes = () => {
        const request = {
            method: 'get',
            url: `${backendUri}/storage/locations/storage_types/`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const createStorageTypes = (body) => {
        const request = {
            method: 'post',
            url: `${backendUri}/storage/locations/storage_types/`,
            headers: getRequestHeader(),
            data: body,
        };
        return makeApiCall(request);
    };

    const deleteStorageTypes = (storageTypeId) => {
        const request = {
            method: 'delete',
            url: `${backendUri}/storage/locations/storage_types/${storageTypeId}`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const getLocations = () => {
        const request = {
            method: 'get',
            url: `${backendUri}/storage/locations/`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const createLocations = (body) => {
        const request = {
            method: 'post',
            url: `${backendUri}/storage/locations/`,
            headers: getRequestHeader(),
            data: body,
        };
        return makeApiCall(request);
    };

    const updateLocations = (body) => {
        const request = {
            method: 'put',
            url: `${backendUri}/storage/locations/${body.id}`,
            headers: getRequestHeader(),
            data: body,
        };
        return makeApiCall(request);
    };

    const deleteLocations = (locationId) => {
        const request = {
            method: 'delete',
            url: `${backendUri}/storage/locations/${locationId}`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const getInventory = () => {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        const request = {
            method: 'get',
            url: `${backendUri}/storage/inventory?date_begin=${startDate.toISOString()}&date_end=${new Date().toISOString()}`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const getStorageFee = () => {
        const request = {
            method: 'get',
            url: `${backendUri}/storage/charges/`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    const createStorageFee = (body) => {
        const request = {
            method: 'post',
            url: `${backendUri}/storage/charges/`,
            headers: getRequestHeader(),
            data: body,
        };
        return makeApiCall(request);
    };

    const updateStorageFee = (body) => {
        const request = {
            method: 'put',
            url: `${backendUri}/storage/charges/${body.id}`,
            headers: getRequestHeader(),
            data: body,
        };
        return makeApiCall(request);
    };

    const deleteStorageFee = (stoargeFeeId) => {
        const request = {
            method: 'delete',
            url: `${backendUri}/storage/charges/${stoargeFeeId}`,
            headers: getRequestHeader(),
        };
        return makeApiCall(request);
    };

    return {
        getStorageTypes,
        createStorageTypes,
        deleteStorageTypes,
        getLocations,
        createLocations,
        updateLocations,
        deleteLocations,
        getInventory,
        getStorageFee,
        createStorageFee,
        updateStorageFee,
        deleteStorageFee,
    };
};

export default useStorageApi;

import { type Table } from '@tanstack/react-table';
import { X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { DataTableViewOptions } from './data-table-viewoptions';
import { DataTableFacetedFilter } from './data-table-facetedfilter';
import React from 'react';

interface DataTableToolbarProps<TData> {
    table: Table<TData>;
    toolbarColumnFilters: any;
    globalFilter: any;
    ActionButton: any;
    MultiSelectRowsAction: any;
}

export function DataTableToolbar<TData>({ table, toolbarColumnFilters, globalFilter, ActionButton, MultiSelectRowsAction }: DataTableToolbarProps<TData>) {
    const isFiltered = table.getPreFilteredRowModel().rows.length > table.getFilteredRowModel().rows.length;
    const rowsSelected = table.getSelectedRowModel().rowsById;
    return (
        <div className="flex items-center justify-between">
            <div className="flex flex-1 items-center space-x-2">
                <Input
                    placeholder="Filter table (on any column) ..."
                    value={(globalFilter as string) ?? ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if ('value' in event.target) {
                            const value = event.target.value;
                            table.setGlobalFilter(value);
                        }
                    }}
                    className="h-8 w-[150px] lg:w-[250px]"
                />
                {toolbarColumnFilters &&
                    toolbarColumnFilters.map((columnFilter: any) => (
                        <DataTableFacetedFilter column={table.getColumn(columnFilter.columnAccessorKey)} title={columnFilter.columnLabel} options={columnFilter.columnOptions} />
                    ))}
                {isFiltered && (
                    <Button
                        variant="ghost"
                        onClick={() => {
                            table.resetColumnFilters();
                            table.resetGlobalFilter();
                        }}
                        className="h-8 px-2 lg:px-3"
                    >
                        Reset
                        <X className="ml-2 h-4 w-4" />
                    </Button>
                )}
            </div>
            <div className="flex items-center space-x-2">
                <DataTableViewOptions table={table} />
                {MultiSelectRowsAction && Object.keys(rowsSelected).length > 1 && <div>{MultiSelectRowsAction}</div>}
                {ActionButton && <>{ActionButton}</>}
            </div>
        </div>
    );
}

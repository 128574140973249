import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Input } from '../../../common/input.tsx';
import { Button } from '@/components/ui/button';
import { FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '../../../common/tooltip.tsx';
import PricingRuleDetailsTierCardCreateForm from './pricingRuleDetailsTierCardForm.jsx';
import { Switch } from '../../../common/switch.tsx';
import { filterSymbolOptions } from '../../../../consts/vars.js';

const PricingRuleDetailsForm = ({ formProps, invoiceTypes, invoiceUiOptions, ruleConditions }) => {
    const { control, setValue } = formProps;
    const {
        fields: conditionsArray,
        remove: removeCondition,
        append: appendCondition,
    } = useFieldArray({
        control,
        name: `rule_conditions`,
    });
    const {
        fields: tiersArray,
        remove: removeTier,
        append: appendTier,
    } = useFieldArray({
        control,
        name: `tiers`,
    });
    const [selectedPricingType, setSelectedPricingType] = useState(formProps.formState.defaultValues.charge_type);
    const [pricingTypes, setPricingTypes] = useState([]);
    const [tiersAreCumulative, setTiersAreCumulative] = useState(formProps.formState.defaultValues.tiers_are_cumulative);
    const [tierError, setTierError] = useState([]);

    useEffect(() => {
        const formErrors = formProps.formState.errors;
        const tierErrors = formErrors?.tiers;
        setTierError(tierErrors);
    }, [formProps.formState.errors]);

    const errorWithTierConditions = tierError?.find((error) => error?.tier_conditions?.message);

    useEffect(() => {
        const pricingTypesData = invoiceTypes
            .filter((obj) => obj.name !== 'package_rate' && !obj.name.toLowerCase().includes('special'))
            .map((obj) => ({ label: obj.name, value: obj.id }));
        setPricingTypes(pricingTypesData);
    }, [invoiceTypes]);

    useEffect(() => {
        if (selectedPricingType && invoiceTypes.length > 0) {
            if (tiersArray.length === 0) {
                appendTier({
                    tier_conditions: {
                        rangeCol: '',
                        rangeStart: '',
                        rangeEnd: '',
                    },
                    pricing_choices: [],
                });
            }
        }
    }, [selectedPricingType, invoiceTypes]);

    const ruleConditionsFormWatch = useWatch({
        control,
        name: 'rule_conditions',
    });

    return (
        <div>
            <div className="mt-4">
                <span className="text-base text-gray-500">Charge Category</span>
                <div className="mt-2 grid grid-cols-2 gap-8">
                    <FormField
                        control={formProps.control}
                        name="charge_type"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Select
                                        {...field}
                                        options={pricingTypes}
                                        onChange={(e) => {
                                            setSelectedPricingType(e);
                                            field.onChange(e);
                                        }}
                                        value={selectedPricingType}
                                        defaultValue={selectedPricingType}
                                        closeMenuOnSelect
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#DBF3D8',
                                                primary: '#92BAA3',
                                            },
                                        })}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
            <div className="mt-12">
                <span className="text-base text-gray-500">Conditions (optional)</span>
            </div>
            {conditionsArray.map((cond, condIdx) => (
                <div key={cond.id} className="grid grid-cols-12 gap-2">
                    <div className="text-gray-500">
                        <div className="mt-4 ml-2">
                            <XCircleIcon
                                onClick={() => {
                                    removeCondition(condIdx);
                                }}
                                className="h-6 w-6"
                            />
                        </div>
                    </div>
                    <div className="col-span-11">
                        <div className="mt-2 flex space-x-2">
                            <div className="w-1/3">
                                <FormField
                                    control={formProps.control}
                                    name={`rule_conditions.[${condIdx}].col`}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Select
                                                    {...field}
                                                    menuPlacement="bottom"
                                                    options={ruleConditions.conditionOptions}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue(`rule_conditions.[${condIdx}].filter`, '');
                                                        setValue(`rule_conditions.[${condIdx}].value`, '');
                                                    }}
                                                    closeMenuOnSelect
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DBF3D8',
                                                            primary: '#92BAA3',
                                                        },
                                                    })}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="w-1/3">
                                <FormField
                                    control={formProps.control}
                                    name={`rule_conditions.[${condIdx}].filter`}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Select
                                                    {...field}
                                                    options={
                                                        ruleConditionsFormWatch[condIdx]?.col.value
                                                            ? ruleConditions.conditionMetadata[ruleConditionsFormWatch[condIdx].col.value].filterOptions
                                                            : filterSymbolOptions
                                                    }
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    closeMenuOnSelect
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DBF3D8',
                                                            primary: '#92BAA3',
                                                        },
                                                    })}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="w-1/3">
                                {ruleConditionsFormWatch[condIdx]?.col.value &&
                                ruleConditions.conditionMetadata[ruleConditionsFormWatch[condIdx].col.value]?.valueOptions !== undefined ? (
                                    <FormField
                                        control={formProps.control}
                                        name={`rule_conditions.[${condIdx}].value`}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Select
                                                        {...field}
                                                        isMulti={['not in', 'in'].includes(ruleConditionsFormWatch[condIdx].filter?.value)}
                                                        options={ruleConditions.conditionMetadata[ruleConditionsFormWatch[condIdx].col.value].valueOptions}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                        }}
                                                        closeMenuOnSelect
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#DBF3D8',
                                                                primary: '#92BAA3',
                                                            },
                                                        })}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                ) : (
                                    <FormField
                                        control={formProps.control}
                                        name={`rule_conditions.[${condIdx}].value`}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input className="bg-white" type="text" placeholder="Enter value" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className="mt-4 text-gray-500">
                <Button
                    type="button"
                    className="h-8"
                    variant="outline"
                    onClick={() => {
                        appendCondition({
                            col: '',
                            filter: '',
                            value: '',
                        });
                    }}
                >
                    <PlusCircleIcon className="h-4 w-4 mr-2" />
                    Add condition
                </Button>
            </div>
            <div className="mt-12">
                <div className="grid grid-rows-2">
                    <span className="text-base text-gray-500">Pricing Definition</span>
                    <div className="flex items-center grid grid-cols-5 pt-1 pb-2">
                        <div className="flex text-md col-span-4 whitespace-nowrap text-wrap">
                            Should charges from each tier be summed up??
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <InformationCircleIcon disabled className="h-4 w-4 ml-2" />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        If chosen, each billable unit incurs a separate charge based on the tier it meets conditions for, and the total charge for the shipment is
                                        the sum of these individual unit charges. If not, the charge for the shipment is determined by the specific tier whose conditions are met.
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </div>
                        <div className="flex col-span-1 justify-end">
                            <FormField
                                control={formProps.control}
                                name="tiers_are_cumulative"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Switch
                                                onCheckedChange={(checked) => {
                                                    setTiersAreCumulative(checked);
                                                    field.onChange(checked);
                                                }}
                                                checked={field.value}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>

                    {errorWithTierConditions && <div className="p-2 bg-secondary rounded-lg text-red-500">{errorWithTierConditions.tier_conditions.message}</div>}

                    {/* Render each tier using PricingRuleDetailsTierCardCreateForm */}
                    {tiersArray.map((tierDetail, tierIdx) => (
                        <div key={tierDetail.id} className="mt-2">
                            <PricingRuleDetailsTierCardCreateForm
                                formProps={formProps}
                                tierIdx={tierIdx}
                                removeTierDetail={removeTier}
                                invoiceUiOptions={invoiceUiOptions}
                                cumulativeTiers={tiersAreCumulative}
                            />
                        </div>
                    ))}

                    {/* Button to add new tier */}
                    <div className="mt-4">
                        <Button
                            type="button"
                            className="h-8"
                            variant="outline"
                            onClick={() => {
                                appendTier({
                                    tier_conditions: {
                                        rangeCol: '',
                                        rangeStart: '',
                                        rangeEnd: '',
                                    },
                                    pricing_choices: [],
                                });
                            }}
                        >
                            <PlusCircleIcon className="h-4 w-4 mr-2" />
                            Add Tier
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PricingRuleDetailsForm;

import { useState, useEffect } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { storage } from '@/firebase';
import useStorage from '@/hooks/useStorage';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';

const fileTypes = ['JPEG', 'JPG', 'PNG', 'GIF'];

const UploadLogo = (companyId: string) => {
    const { logoUrl } = useStorage(companyId);
    const [imageUrl, setImageUrl] = useState<string>('');

    useEffect(() => {
        if (logoUrl) {
            setImageUrl(logoUrl);
        }
    }, [logoUrl]);

    const handleImageUpload = (image: any) => {
        if (image && companyId) {
            // Add a check for companyId
            const imageRef = ref(storage, `logo/${companyId}`);

            uploadBytes(imageRef, image).then((snapshot) => {
                console.log('Uploaded a blob or file!');
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    setImageUrl(downloadURL);
                });
            });
        }
    };

    return (
        <div>
            <FileUploader handleChange={handleImageUpload} name="file" types={fileTypes} />
            {imageUrl && (
                <>
                    <h5 className="text-xs leading-6 text-rails-dark-blue">Current Logo</h5>
                    <img src={imageUrl} alt="Uploaded Logo" style={{ maxWidth: '70px' }} />
                </>
            )}
        </div>
    );
};

export default UploadLogo;

import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import ProductSearchForm from './forms/productSearchForm';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/common/use-toast';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/common/card';

const ProductSearch = ({ open, setOpen, isLoading, setIsLoading, onSearchQuery, resetTable }) => {
    const productSearchSchema = z
        .object({
            name: z.string().optional(),
            sku: z.string().optional(),
            customer: z.string().optional(),
            unitChargeFrom: z.coerce.number().optional().nullable(),
            unitChargeTo: z.coerce.number().optional().nullable(),
            isKit: z.boolean().optional().nullable(),
            excludeFromPickRate: z.boolean().optional().nullable(),
            countAsSingleUnit: z.boolean().optional().nullable(),
        })
        .refine(
            (input) =>
                // Check if at least one field has a value
                Object.values(input).some((value) => value !== null && value !== ''),
            {
                message: 'Please select at least one field to search ',
            },
        )
        .refine(
            (input) => {
                if (input.unitChargeFrom !== null && input.unitChargeFrom < 0) {
                    toast({
                        description: 'Unit charge cannot be negative',
                        variant: 'destructive',
                    });
                    return false;
                }
                if (input.unitChargeTo !== null && input.unitChargeTo < 0) {
                    toast({
                        description: 'Unit charge cannot be negative',
                        variant: 'destructive',
                    });
                    return false;
                }
                if (input.unitChargeFrom !== null && input.unitChargeTo !== null) {
                    if (input.unitChargeFrom > input.unitChargeTo) {
                        toast({
                            description: 'Unit charge from cannot be greater than unit charge to',
                            variant: 'destructive',
                        });
                        return false;
                    }
                }
                return true;
            },
            {
                message: 'Invalid charge range',
            },
        );

    const defaultFormValues = {
        name: '',
        sku: '',
        customer: '',
        unitChargeFrom: null,
        unitChargeTo: null,
        isKit: null,
        excludeFromPickRate: null,
        countAsSingleUnit: null,
    };

    const searchFormProps = useForm({
        resolver: zodResolver(productSearchSchema),
        defaultValues: defaultFormValues,
    });

    const { handleSubmit } = searchFormProps;

    const onSubmit = (values) => {
        setOpen(false);
        const searchQuery = Object.entries(values).reduce(
            (acc, [key, value]) => {
                if (value != null && value !== '') {
                    acc[key] = value;
                }
                return acc;
            },
            { limit: 25000 },
        );
        onSearchQuery(searchQuery);
    };

    return (
        <Card>
            <CardHeader className="grid grid-cols-6">
                <CardTitle className="col-span-5 text-3xl">Search Products</CardTitle>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </CardHeader>
            <CardContent>
                <ProductSearchForm formProps={searchFormProps} isLoading={isLoading} setIsLoading={setIsLoading} />
            </CardContent>
            <CardFooter>
                <div className="grid grid-cols-2">
                    <div className="text-left">
                        <Button onClick={handleSubmit(onSubmit)} type="submit">
                            Submit Search
                        </Button>
                    </div>
                    <div className="text-right">
                        <Button onClick={() => resetTable()}>Reset Table</Button>
                    </div>
                </div>
            </CardFooter>
        </Card>
    );
};

export default ProductSearch;

import { PencilSquareIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import Select from 'react-select';
import { Input } from '../../common/input.tsx';
import { Form, FormField, FormItem, FormControl, FormMessage } from '../../common/form.tsx';
import { Switch } from '../../common/switch.tsx';

const CustomerForm = ({ formProps, customerList }) => (
    <Form {...formProps}>
        <form className="space-y-8">
            <div className="pt-4">
                <div className="flex text-xl font-medium">
                    <CheckCircleIcon className="h-6 w-6 mr-2" />
                    Active
                </div>
                <div className="mt-2 flex items-center space-x-4">
                    <FormField
                        control={formProps.control}
                        name="active"
                        render={({ field }) => (
                            <>
                                <FormItem>
                                    <FormControl>
                                        <Switch onCheckedChange={field.onChange} checked={field.value} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                                <span>Is this customer active?</span>
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-medium">
                    <PencilSquareIcon className="h-6 w-6 mr-2" />
                    Name
                </div>
                <FormField
                    control={formProps.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" placeholder="Enter customer name" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-medium">
                    <PencilSquareIcon className="h-6 w-6 mr-2" />
                    Reference ID
                </div>
                <FormField
                    control={formProps.control}
                    name="customer_reference_id"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" placeholder="Enter reference ID" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-medium">Map to other customer for Invoicing</div>
                <div className="mt-2">
                    <FormField
                        control={formProps.control}
                        name="invoice_customer_id"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Select
                                        {...field}
                                        options={customerList}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#DBF3D8',
                                                primary: '#92BAA3',
                                            },
                                        })}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
        </form>
    </Form>
);

export default CustomerForm;

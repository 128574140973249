import { useState, useEffect } from 'react';
import {
    diffCarrierWmsChargesReconciliationDiffCarrierWmsChargesGet,
    missingWmsDataReconciliationMissingWmsDataGet,
    missingCarrierDataReconciliationMissingCarrierDataGet,
    diffCarrierWmsChargesJsonReconciliationDiffCarrierWmsChargesJsonGet,
    missingWmsDataJsonReconciliationMissingWmsDataJsonGet,
    missingCarrierDataJsonReconciliationMissingCarrierDataJsonGet,
} from '@/client/services.gen';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { DataTable } from '@/components/ui/datatable/data-table';
import {
    type DiffCarrierWmsChargesReconciliationDiffCarrierWmsChargesGetResponse,
    type MissingWmsDataReconciliationMissingWmsDataGetResponse,
    type MissingCarrierDataReconciliationMissingCarrierDataGetResponse,
} from '@/client/types.gen';
import { NumericFormat } from 'react-number-format';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { format, subWeeks } from 'date-fns';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogDescription } from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import Select from 'react-select';

interface GroupedDiffCarrierWmsCharges {
    customer_name: string;
    entries: DiffCarrierWmsChargesReconciliationDiffCarrierWmsChargesGetResponse[];
    count: number;
    total_difference: number;
}

interface GroupedDiffCarrierWmsChargesByCarrier {
    carrier_name: string;
    entries: DiffCarrierWmsChargesReconciliationDiffCarrierWmsChargesGetResponse[];
    count: number;
    total_difference: number;
}

interface GroupedMissingWmsData {
    carrier_name: string;
    entries: MissingWmsDataReconciliationMissingWmsDataGetResponse[];
    count: number;
    totalCharges: number;
}

interface GroupedMissingCarrierData {
    customer_name: string;
    entries: MissingCarrierDataReconciliationMissingCarrierDataGetResponse[];
    count: number;
    totalLabelCharges: number;
}

export default function CarrierExceptions() {
    const [diffCarrierWmsCharges, setDiffCarrierWmsCharges] = useState<GroupedDiffCarrierWmsCharges[] | null>(null);
    const [diffCarrierWmsChargesByCarrier, setDiffCarrierWmsChargesByCarrier] = useState<GroupedDiffCarrierWmsChargesByCarrier[] | null>(null);
    const [missingWmsData, setMissingWmsData] = useState<GroupedMissingWmsData[] | null>(null);
    const [missingCarrierData, setMissingCarrierData] = useState<GroupedMissingCarrierData[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadStartDate, setDownloadStartDate] = useState<Date>(subWeeks(new Date(), 1));
    const [downloadEndDate, setDownloadEndDate] = useState<Date>(new Date());
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const reportOptions = [
        { value: 'diff_carrier_wms', label: 'Carrier vs WMS Charge Differences' },
        { value: 'missing_wms', label: 'Missing WMS Data' },
        { value: 'missing_carrier', label: 'Missing Carrier Data' },
    ];
    const [selectedReport, setSelectedReport] = useState(reportOptions[0].value);

    const fetchDiffCarrierWmsCharges = async () => {
        const response = await diffCarrierWmsChargesReconciliationDiffCarrierWmsChargesGet({
            query: { limit: 100, offset: 0, order_by: 'difference', order_by_desc: true },
        });
        const data = response.data!;
        const groupedData = data.reduce((acc: GroupedDiffCarrierWmsCharges[], item) => {
            const existingGroup = acc.find((group) => group.customer_name === item.customer_name);
            if (existingGroup) {
                existingGroup.entries.push(item);
                existingGroup.count++;
                existingGroup.total_difference += parseFloat(item.carrier_total) - parseFloat(item.label_total);
            } else {
                acc.push({
                    customer_name: item.customer_name,
                    entries: [item],
                    count: 1,
                    total_difference: parseFloat(item.carrier_total) - parseFloat(item.label_total),
                });
            }
            return acc;
        }, []);
        setDiffCarrierWmsCharges(groupedData);

        const groupedDataByCarrier = data.reduce((acc: GroupedDiffCarrierWmsChargesByCarrier[], item) => {
            const existingGroup = acc.find((group) => group.carrier_name === item.carrier_name);
            if (existingGroup) {
                existingGroup.entries.push(item);
                existingGroup.count++;
                existingGroup.total_difference += parseFloat(item.carrier_total) - parseFloat(item.label_total);
            } else {
                acc.push({
                    carrier_name: item.carrier_name,
                    entries: [item],
                    count: 1,
                    total_difference: parseFloat(item.carrier_total) - parseFloat(item.label_total),
                });
            }
            return acc;
        }, []);
        setDiffCarrierWmsChargesByCarrier(groupedDataByCarrier);
    };

    const fetchMissingWmsData = async () => {
        const response = await missingWmsDataReconciliationMissingWmsDataGet({ query: { limit: 100, offset: 0, order_by: 'latest_invoice_date', order_by_desc: true } });
        const data = response.data!;
        const groupedData = data.reduce((acc: GroupedMissingWmsData[], item) => {
            const existingGroup = acc.find((group) => group.carrier_name === item.carrier_name);
            if (existingGroup) {
                existingGroup.entries.push(item);
                existingGroup.count++;
                existingGroup.totalCharges += parseFloat(item.carrier_total);
            } else {
                acc.push({
                    carrier_name: item.carrier_name,
                    entries: [item],
                    count: 1,
                    totalCharges: parseFloat(item.carrier_total),
                });
            }
            return acc;
        }, []);
        setMissingWmsData(groupedData);
    };

    const fetchMissingCarrierData = async () => {
        const response = await missingCarrierDataReconciliationMissingCarrierDataGet({ query: { limit: 100, offset: 0, order_by: 'order_date', order_by_desc: true } });
        const data = response.data!;
        const groupedData = data.reduce((acc: GroupedMissingCarrierData[], item) => {
            const existingGroup = acc.find((group) => group.customer_name === item.customer_name);
            if (existingGroup) {
                existingGroup.entries.push(item);
                existingGroup.count++;
                existingGroup.totalLabelCharges += parseFloat(item.label_total);
            } else {
                acc.push({
                    customer_name: item.customer_name,
                    entries: [item],
                    count: 1,
                    totalLabelCharges: parseFloat(item.label_total),
                });
            }
            return acc;
        }, []);
        setMissingCarrierData(groupedData);
    };

    const fetchData = async () => {
        setIsLoading(true);
        try {
            await Promise.all([fetchDiffCarrierWmsCharges(), fetchMissingWmsData(), fetchMissingCarrierData()]);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDownload = async () => {
        setIsDownloading(true);
        setError(null);

        let response;

        if (selectedReport === 'diff_carrier_wms') {
            response = await diffCarrierWmsChargesJsonReconciliationDiffCarrierWmsChargesJsonGet({
                query: {
                    start_date: downloadStartDate.toISOString(),
                    end_date: downloadEndDate.toISOString(),
                },
            });
        } else if (selectedReport === 'missing_wms') {
            response = await missingWmsDataJsonReconciliationMissingWmsDataJsonGet({
                query: {
                    start_date: downloadStartDate.toISOString(),
                    end_date: downloadEndDate.toISOString(),
                },
            });
        } else if (selectedReport === 'missing_carrier') {
            response = await missingCarrierDataJsonReconciliationMissingCarrierDataJsonGet({
                query: {
                    start_date: downloadStartDate.toISOString(),
                    end_date: downloadEndDate.toISOString(),
                },
            });
        } else {
            setError('Invalid report type selected');
            setIsDownloading(false);
            return;
        }
        // Create a Blob from the response
        const blob = new Blob([response.data!], { type: 'text/csv;charset=utf-8;' });

        // Create a link element and trigger the download
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'carrier_bill_report.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setSuccessMessage('Download successful.');
            setIsDownloading(false);
        } else {
            setError('Download not supported in this browser.');
        }
    };

    const diffCarrierWmsChargesColumns = [
        {
            accessorKey: 'customer_name',
            header: 'Customer',
            cell: ({ row }: { row: any }) => <span className="font-medium">{row.getValue('customer_name')}</span>,
            enableSorting: true,
        },
        {
            accessorKey: 'count',
            header: 'Number of Entries',
            cell: ({ row }: { row: any }) => row.getValue('count'),
            enableSorting: true,
        },
        {
            accessorKey: 'total_difference',
            header: 'Total Difference',
            cell: ({ row }: { row: any }) => (
                <NumericFormat value={row.getValue('total_difference')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
            ),
            enableSorting: true,
        },
    ];

    const diffCarrierWmsChargesByCarrierColumns = [
        {
            accessorKey: 'carrier_name',
            header: 'Carrier',
            cell: ({ row }: { row: any }) => <span className="font-medium">{row.getValue('carrier_name')}</span>,
            enableSorting: true,
        },
        {
            accessorKey: 'count',
            header: 'Number of Entries',
            cell: ({ row }: { row: any }) => row.getValue('count'),
            enableSorting: true,
        },
        {
            accessorKey: 'total_difference',
            header: 'Total Difference',
            cell: ({ row }: { row: any }) => (
                <NumericFormat value={row.getValue('total_difference')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
            ),
            enableSorting: true,
        },
    ];

    const diffCarrierWmsChargesNestedColumns = [
        {
            accessorKey: 'tracking_number',
            header: 'Tracking Number',
            cell: ({ row }: { row: any }) => <span className="font-medium">{row.getValue('tracking_number')}</span>,
            enableSorting: true,
        },
        {
            accessorKey: 'order_number',
            header: 'Order Number',
            cell: ({ row }: { row: any }) => row.getValue('order_number'),
            enableSorting: true,
        },
        {
            accessorKey: 'carrier_total',
            header: 'Carrier Total',
            cell: ({ row }: { row: any }) => (
                <NumericFormat value={row.getValue('carrier_total')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'label_total',
            header: 'Label Total',
            cell: ({ row }: { row: any }) => (
                <NumericFormat value={row.getValue('label_total')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'order_datetime',
            header: 'Order Date',
            cell: ({ row }: { row: any }) => {
                const date = new Date(row.getValue('order_datetime'));
                return date.toISOString().split('T')[0];
            },
            enableSorting: true,
        },
    ];

    const missingWmsDataColumns = [
        {
            accessorKey: 'carrier_name',
            header: 'Carrier',
            cell: ({ row }: { row: any }) => <span className="font-medium">{row.getValue('carrier_name')}</span>,
            enableSorting: true,
        },
        {
            accessorKey: 'count',
            header: 'Number of Entries',
            cell: ({ row }: { row: any }) => row.getValue('count'),
            enableSorting: true,
        },
        {
            accessorKey: 'totalCharges',
            header: 'Total Charges',
            cell: ({ row }: { row: any }) => (
                <NumericFormat value={row.getValue('totalCharges')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
            ),
            enableSorting: true,
        },
    ];

    const missingWmsDataNestedColumns = [
        {
            accessorKey: 'tracking_number',
            header: 'Tracking Number',
            cell: ({ row }: { row: any }) => <span className="font-medium">{row.getValue('tracking_number')}</span>,
            enableSorting: true,
        },
        {
            accessorKey: 'carrier_total',
            header: 'Carrier Total',
            cell: ({ row }: { row: any }) => (
                <NumericFormat value={row.getValue('carrier_total')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'latest_invoice_date',
            header: 'Latest Invoice Date',
            cell: ({ row }: { row: any }) => {
                const date = new Date(row.getValue('latest_invoice_date'));
                return date.toISOString().split('T')[0];
            },
            enableSorting: true,
        },
    ];

    const missingCarrierDataColumns = [
        {
            accessorKey: 'customer_name',
            header: 'Customer',
            cell: ({ row }: { row: any }) => <span className="font-medium">{row.getValue('customer_name')}</span>,
            enableSorting: true,
        },
        {
            accessorKey: 'count',
            header: 'Number of Entries',
            cell: ({ row }: { row: any }) => row.getValue('count'),
            enableSorting: true,
        },
        {
            accessorKey: 'totalLabelCharges',
            header: 'Total Label Charges',
            cell: ({ row }: { row: any }) => (
                <NumericFormat value={row.getValue('totalLabelCharges')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
            ),
            enableSorting: true,
        },
    ];

    const missingCarrierDataNestedColumns = [
        {
            accessorKey: 'tracking_number',
            header: 'Tracking Number',
            cell: ({ row }: { row: any }) => <span>{row.getValue('tracking_number')}</span>,
            enableSorting: true,
        },
        {
            accessorKey: 'order_number',
            header: 'Order Number',
            cell: ({ row }: { row: any }) => <span>{row.getValue('order_number')}</span>,
            enableSorting: true,
        },
        {
            accessorKey: 'order_datetime',
            header: 'Order Date',
            cell: ({ row }: { row: any }) => {
                const date = new Date(row.getValue('order_datetime'));
                return date.toISOString().split('T')[0];
            },
            enableSorting: true,
        },
        {
            accessorKey: 'label_total',
            header: 'Label Total',
            cell: ({ row }: { row: any }) => (
                <NumericFormat value={row.getValue('label_total')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
            ),
            enableSorting: true,
        },
    ];

    return (
        <div className="container mx-auto p-8">
            <div className="flex justify-end">
                <Dialog>
                    <DialogTrigger asChild>
                        <Button>
                            <div className="pr-2">
                                <ArrowDownTrayIcon className="w-4 h-4" />
                            </div>
                            <div>Export</div>
                        </Button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Export Reports</DialogTitle>
                            <DialogDescription></DialogDescription>
                        </DialogHeader>
                        <Tabs defaultValue="download">
                            <TabsList className="grid w-full grid-cols-2">
                                <TabsTrigger value="download" className="w-full">
                                    Download
                                </TabsTrigger>
                                <TabsTrigger value="email" className="w-full">
                                    Schedule Email
                                </TabsTrigger>
                            </TabsList>
                            <TabsContent value="download">
                                <form className="space-y-4">
                                    <div className="space-y-2">
                                        <label className="block text-sm font-medium text-gray-700">Report Type</label>
                                        <Select
                                            value={reportOptions.find((option) => option.value === selectedReport)}
                                            onChange={(selectedOption) => setSelectedReport(selectedOption?.value || '')}
                                            options={reportOptions}
                                            placeholder="Select report type"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                        />
                                    </div>
                                    <div className="flex space-x-4">
                                        <div className="space-y-2">
                                            <label className="block text-sm font-medium text-gray-700">Start Date</label>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant="outline">{format(downloadStartDate, 'PP')}</Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={downloadStartDate} onSelect={(date) => date && setDownloadStartDate(date)} initialFocus />
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                        <div className="space-y-2">
                                            <label className="block text-sm font-medium text-gray-700">End Date</label>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant="outline">{format(downloadEndDate, 'PP')}</Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={downloadEndDate} onSelect={(date) => date && setDownloadEndDate(date)} initialFocus />
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                    </div>
                                    <Button onClick={handleDownload} disabled={isDownloading}>
                                        {isDownloading ? 'Downloading...' : 'Download'}
                                    </Button>
                                    {successMessage && <p className="text-green-500">{successMessage}</p>}
                                    {error && <p className="text-red-500">{error}</p>}
                                </form>
                            </TabsContent>
                            <TabsContent value="email">Coming Soon...</TabsContent>
                        </Tabs>
                    </DialogContent>
                </Dialog>
            </div>
            <h2 className="text-xl font-semibold">Mismatch Between Carrier Charges and Recorded Label Rates</h2>
            <h3 className="text-sm text-gray-500 mb-4">This table only shows the first 100 shipments with the largest discrepancies. Download the full report to see all.</h3>
            <Tabs defaultValue="by_customer">
                <TabsList>
                    <TabsTrigger value="by_customer">By Customer</TabsTrigger>
                    <TabsTrigger value="by_carrier">By Carrier</TabsTrigger>
                </TabsList>
                <TabsContent value="by_customer">
                    {diffCarrierWmsCharges && diffCarrierWmsCharges.length > 0 ? (
                        <DataTable
                            loading={isLoading}
                            data={diffCarrierWmsCharges}
                            columns={diffCarrierWmsChargesColumns}
                            isMultiSelectRows={false}
                            collapsible
                            showActionButtonInToolbar={false}
                            showActionButtonInCollapsible={false}
                            showToolBar
                            collapsibleContent={({ row }) => {
                                if (row?.entries) {
                                    return (
                                        <DataTable
                                            loading={isLoading}
                                            data={row.entries}
                                            columns={diffCarrierWmsChargesNestedColumns}
                                            isMultiSelectRows={false}
                                            showPagination={false}
                                            showToolBar={false}
                                            showActionButtonInToolbar={false}
                                            showActionButtonInCollapsible={false}
                                            collapsible={false}
                                            isDownloadable={true}
                                            downloadData={row.entries}
                                            tableName="diff_carrier_wms_charges"
                                        />
                                    );
                                }
                                return null;
                            }}
                        />
                    ) : (
                        <p>No data available for Carrier Charges and Recorded Label Rates mismatch.</p>
                    )}
                </TabsContent>
                <TabsContent value="by_carrier">
                    {diffCarrierWmsChargesByCarrier && diffCarrierWmsChargesByCarrier.length > 0 ? (
                        <DataTable
                            loading={isLoading}
                            data={diffCarrierWmsChargesByCarrier}
                            columns={diffCarrierWmsChargesByCarrierColumns}
                            isMultiSelectRows={false}
                            collapsible
                            showActionButtonInToolbar={false}
                            showActionButtonInCollapsible={false}
                            showToolBar
                            collapsibleContent={({ row }) => {
                                if (row?.entries) {
                                    return (
                                        <DataTable
                                            loading={isLoading}
                                            data={row.entries}
                                            columns={diffCarrierWmsChargesNestedColumns}
                                            isMultiSelectRows={false}
                                            showPagination={false}
                                            showToolBar={false}
                                            showActionButtonInToolbar={false}
                                            showActionButtonInCollapsible={false}
                                            collapsible={false}
                                            isDownloadable={true}
                                            downloadData={row.entries}
                                            tableName="diff_carrier_wms_charges_by_carrier"
                                        />
                                    );
                                }
                                return null;
                            }}
                        />
                    ) : (
                        <p>No data available for Carrier Charges and Recorded Label Rates mismatch.</p>
                    )}
                </TabsContent>
            </Tabs>

            <h2 className="text-xl font-semibold mt-8">Shipments Billed But Not Recorded in WMS</h2>
            <h3 className="text-sm text-gray-500 mb-4">This table only shows the latest 100 shipments missing from WMS. Download the full report to see all.</h3>
            {missingWmsData && missingWmsData.length > 0 ? (
                <DataTable
                    loading={isLoading}
                    data={missingWmsData}
                    columns={missingWmsDataColumns}
                    isMultiSelectRows={false}
                    collapsible
                    showActionButtonInToolbar={false}
                    showActionButtonInCollapsible={false}
                    showToolBar
                    collapsibleContent={({ row }) => {
                        if (row?.entries) {
                            return (
                                <DataTable
                                    loading={isLoading}
                                    data={row.entries}
                                    columns={missingWmsDataNestedColumns}
                                    isMultiSelectRows={false}
                                    showPagination={false}
                                    showToolBar={false}
                                    showActionButtonInToolbar={false}
                                    showActionButtonInCollapsible={false}
                                    collapsible={false}
                                    isDownloadable={true}
                                    downloadData={row.entries}
                                    tableName="missing_wms_data"
                                />
                            );
                        }
                        return null;
                    }}
                />
            ) : (
                <p>No data available for Shipments Billed But Not Recorded in WMS.</p>
            )}

            <h2 className="text-xl font-semibold mt-8">Shipments Recorded in WMS But Not Charged By Carrier</h2>
            <h3 className="text-sm text-gray-500 mb-4">
                This table only shows the latest 100 shipments missing charges from carrier invoices. Download the full report to see all.
            </h3>
            {missingCarrierData && missingCarrierData.length > 0 ? (
                <DataTable
                    loading={isLoading}
                    data={missingCarrierData}
                    columns={missingCarrierDataColumns}
                    isMultiSelectRows={false}
                    collapsible
                    showActionButtonInToolbar={false}
                    showActionButtonInCollapsible={false}
                    showToolBar
                    collapsibleContent={({ row }) => {
                        if (row?.entries) {
                            return (
                                <DataTable
                                    loading={isLoading}
                                    data={row.entries}
                                    columns={missingCarrierDataNestedColumns}
                                    isMultiSelectRows={false}
                                    showPagination={false}
                                    showToolBar={false}
                                    showActionButtonInToolbar={false}
                                    showActionButtonInCollapsible={false}
                                    collapsible={false}
                                    isDownloadable={true}
                                    downloadData={row.entries}
                                    tableName="missing_carrier_data"
                                />
                            );
                        }
                        return null;
                    }}
                />
            ) : (
                <p>No data available for Shipments Recorded in WMS But Not Charged By Carrier.</p>
            )}
        </div>
    );
}

/* eslint-disable no-nested-ternary */
import Select from 'react-select';

import { invoiceConfigDateTypeOptions, invoiceConfigDeliveryMethodOptions } from '../../../../consts/vars.js';
import { Form, FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';
import CronSchedule from '../../../common/cronSchedule.jsx';
import { Checkbox } from '../../../common/checkbox.tsx';

const ConfigForm = ({ formProps, setInvoiceCronString, setChargeInvoiceCronString, cronValidationRef }) => (
    <Form {...formProps}>
        <form className="space-y-2">
            <div className="pt-4">
                <div className="flex text-xl font-bold mt-2">Delivery Cadence</div>
                <div className="flex text-sm">Define invoicing delivery cadence. You can choose to deliver invoice-level charges on a separate cadence below.</div>
                <CronSchedule
                    className="mt-2"
                    formProps={formProps}
                    setCronString={setInvoiceCronString}
                    cronValidationRef={cronValidationRef}
                    initialCronString={formProps.getValues().invoice_cadence}
                    allowSetHourly={false}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-bold mt-2">Billable Activity Qualifier</div>
                <div className="flex text-sm">Please select the date field used for qualifying billable activity</div>
                <FormField
                    control={formProps.control}
                    name="date_ship_or_order"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Select
                                    {...field}
                                    className="mt-2"
                                    options={invoiceConfigDateTypeOptions}
                                    placeholder="Date Type selection"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DBF3D8',
                                            primary: '#92BAA3',
                                        },
                                    })}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-bold mt-2">Delivery Method</div>
                <FormField
                    control={formProps.control}
                    name="delivery_method"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Select
                                    {...field}
                                    options={invoiceConfigDeliveryMethodOptions}
                                    placeholder="Choose delivery method for customer invoices"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DBF3D8',
                                            primary: '#92BAA3',
                                        },
                                    })}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-bold mt-2">Invoice Level Charges Delivery Cadence</div>
                <div className="flex text-sm">Invoice-level charges can be delivered at a different cadence from the rest of your invoice in Rails.</div>
                {!formProps.watch('same_cadence_as_invoice') && (
                    <CronSchedule
                        className="mt-2"
                        formProps={formProps}
                        setCronString={setChargeInvoiceCronString}
                        cronValidationRef={cronValidationRef}
                        initialCronString={formProps.getValues().charge_invoice_cadence}
                        allowSetHourly={false}
                    />
                )}
                <FormField
                    control={formProps.control}
                    name="same_cadence_as_invoice"
                    render={({ field }) => (
                        <FormItem>
                            <div className="flex items-center space-x-2">
                                <Checkbox defaultChecked={false} checked={field.value} onCheckedChange={field.onChange} />
                                <label htmlFor="terms" className="capitalize text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                    Same as Invoice Delivery Cadence
                                </label>
                            </div>
                        </FormItem>
                    )}
                />
            </div>
            {/* <div className="pt-4">
                <div className="flex text-md font-medium">Invoice charge source</div>
                <FormField
                    control={formProps.control}
                    name="shipping_charge_label_or_carrier"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Select
                                    {...field}
                                    className="mt-2"
                                    options={invoiceConfigChargeTypeOptions}
                                    placeholder="Charge Type for Shipping Invoice selection"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DBF3D8',
                                            primary: '#92BAA3',
                                        },
                                    })}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-md font-medium">Invoice using label charge if carrier bill is not available?</div>
                <FormField
                    control={formProps.control}
                    name="use_label_data_if_carrier_missing"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Select
                                    {...field}
                                    className="mt-2"
                                    options={invoiceConfigShippingInvoiceOptions}
                                    placeholder="Use label charge if carrier bill is not available"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DBF3D8',
                                            primary: '#92BAA3',
                                        },
                                    })}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div> */}
        </form>
    </Form>
);

export default ConfigForm;

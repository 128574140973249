import * as React from 'react';
import { CurrencyDollarIcon, ReceiptPercentIcon } from '@heroicons/react/20/solid';
import { cn } from '../../lib/utils';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    specialType?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ specialType = '', className, type, ...props }, ref) => {
    return (
        <div className="flex w-full h-10 items-center">
            {specialType == 'dollar' && (
                <div className="flex h-10 w-full rounded-md border border-input bg-gray-800 text-white p-2 text-sm">
                    <span className="h-5 w-5">
                        <CurrencyDollarIcon />
                    </span>
                </div>
            )}
            <input
                type={type}
                className={cn(
                    'flex h-10 w-full rounded-md border border-input bg-transparent text-sm px-3 py-2 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                    specialType == 'percent' && 'text-right',
                    className,
                )}
                ref={ref}
                {...props}
            />
            {specialType == 'percent' && (
                <div className="flex h-10 w-full rounded-md border border-input bg-gray-800 text-white p-2 text-sm">
                    <span className="float-right h-5 w-5">
                        <ReceiptPercentIcon />
                    </span>
                </div>
            )}
        </div>
    );
});
Input.displayName = 'Input';

export { Input };

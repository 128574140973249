import * as z from 'zod';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, SheetFooter } from '../common/sheet.tsx';
import CustomerForm from './forms/customerEditForm';
import LoadingSpinner from '../common/loadingSpinner';
import useInvoiceApi from '../../hooks/api/useInvoiceApi';

const CustomerEdit = ({ selectedCustomer, customerList }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { updateInvoiceCustomer } = useInvoiceApi();

    let mappingOptions = customerList.filter((obj) => obj.invoice_customer_id === null);
    mappingOptions = customerList.filter((obj) => obj.id !== selectedCustomer.id);
    const transformedMappingOptions = mappingOptions.map((obj) => ({ label: obj.name, value: obj.id }));

    const dropDownSchema = z.object({
        label: z.string().nullable(),
        value: z.string().nullable(),
    });

    const customerSchema = z.object({
        active: z.boolean(),
        name: z.string(),
        customer_reference_id: z.string().nullable(),
        invoice_customer_id: dropDownSchema.nullable(),
    });

    const formProps = useForm({
        resolver: zodResolver(customerSchema),
        defaultValues: selectedCustomer,
    });

    const { handleSubmit, reset } = formProps;

    useEffect(() => {
        reset(selectedCustomer);
    }, [selectedCustomer]);

    const onSubmit = (values) => {
        const updatedInvoiceCustomerId = values.invoice_customer_id?.value ?? values.invoice_customer_id;

        const updatedValues = {
            ...values,
            invoice_customer_id: updatedInvoiceCustomerId,
        };

        const diff = Object.fromEntries(Object.entries(updatedValues).filter(([k, v]) => selectedCustomer[k] !== v));

        setIsLoading(true);
        updateInvoiceCustomer(selectedCustomer.id, diff).then((resp) => {
            if (resp) {
                setIsLoading(false);
                window.location.reload();
            }
        });
    };

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                    className="ml-4"
                >
                    Edit
                </Button>
            </SheetTrigger>
            {isDialogOpen && (
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">Edit Customer</SheetTitle>
                        <SheetDescription className="text-rails-dark-blue">
                            <CustomerForm formProps={formProps} customerList={transformedMappingOptions} />
                        </SheetDescription>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <Button disabled={isLoading} onClick={handleSubmit((e) => onSubmit(e))} type="submit">
                                {isLoading && (
                                    <>
                                        <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                        Saving
                                    </>
                                )}
                                {!isLoading && <>Save</>}
                            </Button>
                        </div>
                    </SheetFooter>
                </SheetContent>
            )}
        </Sheet>
    );
};

export default CustomerEdit;

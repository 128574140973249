import { useState, useEffect } from 'react';
import { Table, TableHeader, TableHead, TableBody, TableRow, TableCell } from '@/components/common/table';
import { Badge } from '@/components/common/badge.tsx';
import { getOneInvoiceProductsProductIdGet } from '@/client/services.gen.ts';
import type { Product } from '@/client';

const KitComponentTable = (selectedProduct: Product) => {
    const [skus, setSkus] = useState({});

    useEffect(() => {
        const fetchSkus = async () => {
            const skuPromises = selectedProduct.kit_items!.map(async (item) => {
                const res = await getOneInvoiceProductsProductIdGet({ path: { product_id: item.product_id } });
                return {
                    id: item.product_id,
                    sku: res.data!.sku,
                };
            });

            const skuResults = await Promise.all(skuPromises);
            const skuMap = skuResults.reduce((acc, { id, sku }) => ({ ...acc, [id]: sku }), {});
            setSkus(skuMap);
        };
        if (selectedProduct && selectedProduct.is_kit) {
            fetchSkus();
        }
    }, []);

    return (
        selectedProduct && (
            <Table className="w-full">
                <TableHeader className="bg-slate-50">
                    <TableRow>
                        <TableHead>SKU</TableHead>
                        <TableHead>Quantity</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {selectedProduct && selectedProduct.kit_items
                        ? selectedProduct.kit_items.map((col) => (
                              <TableRow key={col.product_id}>
                                  <TableCell className="pr-2">
                                      <Badge variant="outline">{skus[col.product_id]}</Badge>
                                  </TableCell>
                                  <TableCell className="pr-2">
                                      <Badge variant="outline">{col.quantity}</Badge>
                                  </TableCell>
                              </TableRow>
                          ))
                        : []}
                </TableBody>
            </Table>
        )
    );
};

export default KitComponentTable;

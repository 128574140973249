import Select from 'react-select';
import { Square3Stack3DIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Input } from '../../../common/input.tsx';
import { Form, FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';
import useStorageApi from '../../../../hooks/api/useStorageApi';

const LocationCreateForm = ({ formProps }) => {
    const [storageTypes, setStorageTypes] = React.useState([]);
    const { getStorageTypes } = useStorageApi();

    React.useEffect(() => {
        getStorageTypes().then(({ data }) => {
            const storageTypeList = data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setStorageTypes(storageTypeList);
        });
    }, []);

    return (
        <Form {...formProps}>
            <form className="space-y-8">
                <div className="pt-4">
                    <div className="flex text-xl font-medium">Name</div>
                    <FormField
                        control={formProps.control}
                        name="name"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input className="mt-2" placeholder="Enter location name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="pt-4">
                    <div className="flex text-xl font-medium">
                        <Square3Stack3DIcon className="h-6 w-6 mr-2" />
                        Storage Type
                    </div>
                    <div className="mt-2">
                        <FormField
                            control={formProps.control}
                            name="storage_type"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            options={storageTypes}
                                            placeholder="Storage Type"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DBF3D8',
                                                    primary: '#92BAA3',
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
            </form>
        </Form>
    );
};

export default LocationCreateForm;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import PricingRules from '../invoices/pricingRules/pricingRules';
import InvoiceOverview from '../invoices/overview/invoiceOverview';
import CustomerChargeDashboard from './tables/customerChargeDashboard';
import useInvoiceApi from '../../hooks/api/useInvoiceApi';
import { Checkbox } from '../common/checkbox.tsx';

const Customers = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState(null);
    const { customerId } = useParams();
    const { getInvoiceCustomer } = useInvoiceApi();

    const fetchCustomer = () => {
        setIsLoading(true);
        getInvoiceCustomer(customerId).then((res) => {
            setCustomer(res.data);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchCustomer();
    }, []);

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Customer: {customer?.name}</h1>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-xl text-rails-dark-blue">
                                Active <Checkbox checked={customer?.active} />
                            </h1>
                            <h1 className="text-xl text-rails-dark-blue">
                                {customer?.invoice_customer_id === null ? '' : `Invoices mapped to ${customer?.invoice_customer_name}`}
                            </h1>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-5">
                                <h2 className="text-2xl font-bold text-rails-dark-blue">Pricing Rules</h2>
                                <PricingRules dataTableOnly showCreate={false} customerIdFilter={customerId} />
                            </div>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-5">
                                <h2 className="text-2xl font-bold text-rails-dark-blue">Charge Type</h2>
                                <CustomerChargeDashboard filter={`customer_id='${customerId}'`} />
                            </div>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-5">
                                <h2 className="text-2xl font-bold text-rails-dark-blue">Invoice Detail</h2>
                                <InvoiceOverview dataTableOnly filter={`customer_id='${customerId}'`} />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Customers;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';

import { DataTable } from '../../common/datatable/dataTable.tsx';
import { DataTableColumnHeader } from '../../common/datatable/dataTableColumnHeader.tsx';

import LocationCreateRate from './locationCreate';
import LocationView from './locationView';
import { getInvoiceRuleDisplayName } from '../../../utils/invoice';
import useStorageApi from '../../../hooks/api/useStorageApi';

const Locations = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [open, setOpen] = useState(false);
    const { getLocations } = useStorageApi();

    const fetchLocations = async () => {
        setIsLoading(true);
        getLocations().then((res) => {
            setTableData(res.data);
            setIsLoading(false);
        });
    };

    const openLocationView = (row) => {
        setSelectedLocation(tableData.filter((data) => data.id === row.original.id)[0]);
        setOpen(true);
    };

    useEffect(() => {
        fetchLocations();
    }, []);

    const columns = [
        {
            accessorKey: 'name',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
            cell: ({ row }) => (
                <div className="w-[200px] font-medium hover:underline" onClick={() => openLocationView(row)}>
                    <a href="#">{getInvoiceRuleDisplayName(row.getValue('name'))}</a>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'warehouse',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Warehouse" />,
            cell: ({ row }) => <div className="w-[100px] font-medium">{row.original.warehouse?.name}</div>,
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'type',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Type" />,
            cell: ({ row }) => <div className="w-[100px] font-medium">{row.original.storage_type?.name}</div>,
            enableSorting: true,
            enableHiding: false,
        },
        // {
        //     id: 'actions',
        //     enableHiding: false,
        //     cell: ({ row }) => (
        //         <DropdownMenu>
        //             <DropdownMenuTrigger asChild>
        //                 <Button variant="ghost" className="h-8 w-8 p-0">
        //                     <span className="sr-only">Open menu</span>
        //                     <MoreHorizontal className="h-4 w-4" />
        //                 </Button>
        //             </DropdownMenuTrigger>
        //             <DropdownMenuContent align="end">
        //                 <DropdownMenuItem
        //                     onClick={() => {
        //                         deleteLocation(row.original.id).then(() => {
        //                             fetchLocations();
        //                         });
        //                     }}
        //                 >
        //                     Delete Location
        //                 </DropdownMenuItem>
        //             </DropdownMenuContent>
        //         </DropdownMenu>
        //     ),
        // },
    ];

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Locations</h1>
                            <h2 className="text-xs text-rails-dark-blue">Manage locations.</h2>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="container mx-auto py-20">
                                {tableData && (
                                    <DataTable
                                        loading={isLoading}
                                        data={tableData}
                                        columns={columns}
                                        ActionButton={<LocationCreateRate fetchLocations={fetchLocations} />}
                                        isMultiSelectRows={false}
                                        isDownloadable
                                        downloadData={tableData}
                                        tableName="locations"
                                        showActionButtonInToolbar
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <LocationView open={open} setOpen={setOpen} selectedLocation={selectedLocation} fetchLocations={fetchLocations} />
        </div>
    );
};

export default Locations;

/* eslint-disable no-nested-ternary */
import { useFieldArray } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Button } from '@/components/ui/button';
import { Select as RegSelect, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../../../common/select.tsx';
import { Card, CardContent, CardHeader, CardTitle } from '../../../common/card.tsx';
import { FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';
import { Input } from '../../../common/input.tsx';

const PricingRuleDetailsTierCardCreateForm = ({ formProps, tierIdx, removeTierDetail, invoiceUiOptions, cumulativeTiers }) => {
    // Manage pricing choices for the specific tier
    const {
        fields: pricingChoicesArray,
        remove: removePricingChoice,
        append: appendPricingChoice,
    } = useFieldArray({
        control: formProps.control,
        name: `tiers[${tierIdx}].pricing_choices`,
    });

    const pricingChoiceFilter = [
        {
            label: 'maximum',
            value: 'MAX',
        },
        {
            label: 'minimum',
            value: 'MIN',
        },
        {
            label: 'sum',
            value: 'SUM',
        },
    ];

    // Initialize state for selected methods and columns
    const [selectedMethods, setSelectedMethods] = useState(pricingChoicesArray.map((choice, idx) => formProps.getValues(`tiers[${tierIdx}].pricing_choices[${idx}].method`) || ''));

    const [selectedPricingCols, setSelectedPricingCols] = useState(
        pricingChoicesArray.map((choice, idx) => formProps.getValues(`tiers[${tierIdx}].pricing_choices[${idx}].col`) || ''),
    );

    // Update the state when pricingChoicesArray changes
    useEffect(() => {
        const updatedMethods = pricingChoicesArray.map((choice, idx) => formProps.getValues(`tiers[${tierIdx}].pricing_choices[${idx}].method`) || '');
        setSelectedMethods(updatedMethods);

        const updatedCols = pricingChoicesArray.map((choice, idx) => formProps.getValues(`tiers[${tierIdx}].pricing_choices[${idx}].col`) || '');
        setSelectedPricingCols(updatedCols);
    }, [pricingChoicesArray, formProps.getValues, tierIdx]);

    // Function to handle method change
    const handleMethodChange = (e, index) => {
        const updatedMethods = [...selectedMethods];
        updatedMethods[index] = e; // Update the method at the specified index
        setSelectedMethods(updatedMethods); // Update the state
        formProps.setValue(`tiers[${tierIdx}].pricing_choices[${index}].method`, e); // Update form's state
    };

    // Function to handle column change
    const handleColChange = (e, index) => {
        const updatedCols = [...selectedPricingCols];
        updatedCols[index] = e; // Update the column at the specified index
        setSelectedPricingCols(updatedCols); // Update the state
        formProps.setValue(`tiers[${tierIdx}].pricing_choices[${index}].col`, e); // Update form's state
    };

    return (
        <Card className="bg-gray-100 border-transparent shadow-none">
            <CardHeader>
                <CardTitle>
                    <div className="flow-root">
                        <div className="float-left text-gray-700">Tier {tierIdx + 1}</div>
                        {tierIdx > 0 && (
                            <div className="float-right">
                                <XCircleIcon onClick={() => removeTierDetail(tierIdx)} className="h-6 w-6" />
                            </div>
                        )}
                    </div>
                </CardTitle>
            </CardHeader>
            <CardContent>
                <div className="flex items-center text-base space-x-2">
                    <span>Tier defined as</span>

                    <FormField
                        control={formProps.control}
                        name={`tiers[${tierIdx}].tier_conditions.rangeCol`}
                        render={({ field }) => (
                            <FormItem>
                                <RegSelect {...field} onValueChange={(value) => field.onChange(value)} defaultValue={field.value} value={field.value} className="w-20">
                                    <SelectTrigger className="bg-white w-full">
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {invoiceUiOptions.tierConditionOptions.map((col) => (
                                                <SelectItem key={col.value} value={col.value}>
                                                    {col.label}
                                                </SelectItem>
                                            ))}
                                        </SelectGroup>
                                    </SelectContent>
                                </RegSelect>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <span>between</span>

                    <FormField
                        control={formProps.control}
                        name={`tiers[${tierIdx}].tier_conditions.rangeStart`}
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input type="number" {...field} className="w-16 text-center bg-white" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <span>and</span>

                    <FormField
                        control={formProps.control}
                        name={`tiers[${tierIdx}].tier_conditions.rangeEnd`}
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input type="number" {...field} className="w-16 text-center bg-white" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <div>
                    <div className="mt-6 text-base">
                        <div className="flex items-center mb-2 space-x-2">
                            <span className="w-content">Pricing defined as</span>
                        </div>

                        {pricingChoicesArray.length > 1 && (
                            <div className="w-48">
                                <FormField
                                    control={formProps.control}
                                    name={`tiers[${tierIdx}].pricing_choices_filter`}
                                    render={({ field }) => (
                                        <FormItem>
                                            <RegSelect {...field} onValueChange={(value) => field.onChange(value)} defaultValue={field.value} value={field.value}>
                                                <SelectTrigger className="bg-white w-full">
                                                    <SelectValue />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        {pricingChoiceFilter.map((col) => (
                                                            <SelectItem key={col.value} value={col.value}>
                                                                {col.label}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </RegSelect>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        )}

                        {pricingChoicesArray.map((pricingDetail, pricingChoiceIdx) => (
                            <div key={pricingChoiceIdx} className="flex items-center space-x-2 mt-2">
                                {/* Method Select */}
                                <FormField
                                    control={formProps.control}
                                    name={`tiers[${tierIdx}].pricing_choices[${pricingChoiceIdx}].method`}
                                    render={({ field }) => (
                                        <FormItem>
                                            <RegSelect
                                                onValueChange={(e) => handleMethodChange(e, pricingChoiceIdx)}
                                                defaultValue={field.value}
                                                value={selectedMethods[pricingChoiceIdx]} // Use selectedMethods for the specific pricing choice
                                                className="w-20"
                                            >
                                                <SelectTrigger className="bg-white w-full">
                                                    <SelectValue />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        {invoiceUiOptions.pricingMethodOptions.map((method) => (
                                                            <SelectItem key={method.value} value={method.value}>
                                                                {method.label}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </RegSelect>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                {/* Conditionally render fields based on selected method */}
                                {selectedMethods[pricingChoiceIdx] && selectedMethods[pricingChoiceIdx] !== 'fixed_cost' && (
                                    <div className="flex items-center space-x-2">
                                        {selectedMethods[pricingChoiceIdx].includes('margin') && <span>on</span>}

                                        <FormField
                                            control={formProps.control}
                                            name={`tiers[${tierIdx}].pricing_choices[${pricingChoiceIdx}].col`}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <RegSelect
                                                        onValueChange={(e) => handleColChange(e, pricingChoiceIdx)}
                                                        defaultValue={field.value}
                                                        value={selectedPricingCols[pricingChoiceIdx]}
                                                        className="w-20"
                                                    >
                                                        <SelectTrigger className="bg-white w-full">
                                                            <SelectValue />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectGroup>
                                                                {selectedMethods[pricingChoiceIdx].includes('margin') &&
                                                                    invoiceUiOptions.pricingMethodMarginOptions.map((col) => (
                                                                        <SelectItem key={col.value} value={col.value}>
                                                                            {col.label}
                                                                        </SelectItem>
                                                                    ))}
                                                                {selectedMethods[pricingChoiceIdx].includes('variable') &&
                                                                    invoiceUiOptions.pricingMethodVariableOptions.map((col) => (
                                                                        <SelectItem key={col.value} value={col.value}>
                                                                            {col.label}
                                                                        </SelectItem>
                                                                    ))}
                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </RegSelect>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                )}

                                {/* Input for value */}
                                <FormField
                                    control={formProps.control}
                                    name={`tiers[${tierIdx}].pricing_choices[${pricingChoiceIdx}].value`}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    type="number"
                                                    {...field}
                                                    className="w-16 text-center bg-white"
                                                    specialType={
                                                        selectedMethods[pricingChoiceIdx] == null
                                                            ? ''
                                                            : selectedMethods[pricingChoiceIdx].includes('percent')
                                                              ? 'percent'
                                                              : 'dollar'
                                                    }
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                {/* Render button to remove pricing choice */}
                                {pricingChoiceIdx > 0 && (
                                    <div>
                                        <XCircleIcon onClick={() => removePricingChoice(pricingChoiceIdx)} className="h-6 w-6" />
                                    </div>
                                )}
                            </div>
                        ))}

                        {!cumulativeTiers && (
                            <div className="flex justify-end mt-4">
                                <Button
                                    type="button"
                                    className="bg-primary text-white"
                                    variant="outline"
                                    onClick={() => {
                                        appendPricingChoice({
                                            method: '',
                                            value: '',
                                            col: '',
                                        });
                                    }}
                                >
                                    <PlusCircleIcon className="h-4 w-4 mr-2" />
                                    Add Pricing Choice
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default PricingRuleDetailsTierCardCreateForm;

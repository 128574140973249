import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getInvoiceSummaryInvoiceInvoiceSummariesGet } from '@/client/services.gen';
import { NumericFormat } from 'react-number-format';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';

export default function Notifications() {
    const [username, setUsername] = useState('');
    const [invoiceStats, setInvoiceStats] = useState({
        weeklyInvoiceCount: 0,
        weeklyInvoiceCountChange: 0,
        totalInvoicedAmount: 0,
        totalInvoicedAmountChange: 0,
        openInvoicesCount: 0,
        openInvoicesTotalAmount: 0
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const auth = getAuth();
                const user = auth.currentUser;
                if (user) {
                    setUsername(user.displayName || user.email || '');
                }

                await fetchInvoiceStats();
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const fetchInvoiceStats = async () => {
        try {
            const response = await getInvoiceSummaryInvoiceInvoiceSummariesGet();
            const invoices = Array.isArray(response.data) ? response.data : [];

            const now = new Date();
            const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            const twoWeeksAgo = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000);

            const thisWeekInvoices = invoices.filter(invoice => new Date(invoice.billing_period_end) >= oneWeekAgo);
            const lastWeekInvoices = invoices.filter(invoice => new Date(invoice.billing_period_end) >= twoWeeksAgo && new Date(invoice.billing_period_end) < oneWeekAgo);

            const weeklyInvoiceCount = thisWeekInvoices.length;
            const lastWeekInvoiceCount = lastWeekInvoices.length;
            const weeklyInvoiceCountChange = ((weeklyInvoiceCount - lastWeekInvoiceCount) / lastWeekInvoiceCount) * 100;

            const totalInvoicedAmount = thisWeekInvoices.reduce((sum, invoice) => sum + parseFloat(invoice.total_invoice_amount), 0);
            const lastWeekTotalInvoicedAmount = lastWeekInvoices.reduce((sum, invoice) => sum + parseFloat(invoice.total_invoice_amount), 0);
            const totalInvoicedAmountChange = lastWeekTotalInvoicedAmount !== 0
                ? ((totalInvoicedAmount - lastWeekTotalInvoicedAmount) / lastWeekTotalInvoicedAmount) * 100
                : totalInvoicedAmount > 0 ? 100 : 0;

            const openInvoices = invoices.filter(invoice => invoice.status === 'open');
            const openInvoicesCount = openInvoices.length;
            const openInvoicesTotalAmount = openInvoices.reduce((sum, invoice) => sum + parseFloat(invoice.total_invoice_amount), 0);

            setInvoiceStats({
                weeklyInvoiceCount,
                weeklyInvoiceCountChange: isNaN(weeklyInvoiceCountChange) ? 0 : weeklyInvoiceCountChange,
                totalInvoicedAmount,
                totalInvoicedAmountChange: isNaN(totalInvoicedAmountChange) ? 0 : totalInvoicedAmountChange,
                openInvoicesCount,
                openInvoicesTotalAmount
            });
        } catch (error) {
            console.error('Error fetching invoice stats:', error);
        }
    };

    if (isLoading) {
        return (
            <div className="h-screen flex items-center justify-center">
                <p className="text-xl">Loading...</p>
            </div>
        );
    }

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <h1 className="text-xl font-bold mb-4 ml-8">Hello, {username}!</h1>
                        <div className="grid grid-cols-4 gap-4 ml-8 mt-8 mr-8">
                            <Card>
                                <CardHeader>
                                    <CardTitle>Invoices Generated</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-3xl font-bold">{invoiceStats.weeklyInvoiceCount}</p>
                                    <p className={`text-sm ${invoiceStats.weeklyInvoiceCountChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                                        {invoiceStats.weeklyInvoiceCountChange >= 0 ? '↑' : '↓'} {Math.abs(invoiceStats.weeklyInvoiceCountChange).toFixed(2)}% from last week
                                    </p>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Total Invoiced Amount</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-3xl font-bold">
                                        <NumericFormat value={invoiceStats.totalInvoicedAmount} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                                    </p>
                                    <p className={`text-sm ${invoiceStats.totalInvoicedAmountChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                                        {invoiceStats.totalInvoicedAmountChange >= 0 ? '↑' : '↓'} {Math.abs(invoiceStats.totalInvoicedAmountChange).toFixed(2)}% from last week
                                    </p>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Open Invoices</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-3xl font-bold">{invoiceStats.openInvoicesCount}</p>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Total Amount for Open Invoices</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-3xl font-bold">
                                        <NumericFormat value={invoiceStats.openInvoicesTotalAmount} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                                    </p>
                                </CardContent>
                            </Card>
                        </div>
                        <div className="ml-8 mt-4 mr-8">
                            <Link to="/invoices/insights">
                                <Button>View invoices</Button>
                            </Link>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

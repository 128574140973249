/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';

import { DataTable } from '../../common/datatable/dataTable.tsx';
import { DataTableColumnHeader } from '../../common/datatable/dataTableColumnHeader.tsx';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi';

const CustomerChargeDashboard = ({ filter = null }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const { getCustomerChargeDashboard } = useInvoiceApi();

    const fetchRules = () => {
        setIsLoading(true);

        getCustomerChargeDashboard(filter).then((res) => {
            setTableData(res.data);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchRules();
    }, []);

    const columns = [
        {
            accessorKey: 'charge_type',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Charge Name" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="w-[200px]">{row.getValue('charge_type')}</span>
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_amount',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Latest Invoice Amount" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px]">{row.getValue('charge_amount')}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_usage',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Quantity" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px]">{row.getValue('charge_usage')}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'prev_charge_amount',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Previous Invoice Amount" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px]">{row.getValue('prev_charge_amount')}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'prev_total_shipment',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Previous Total Shipment" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px]">{row.getValue('prev_total_shipment')}</span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'change_amount',
            header: ({ column }) => <DataTableColumnHeader column={column} title="Change in Charge Amount" />,
            cell: ({ row }) => (
                <div className="flex flex-wrap space-x-2">
                    {row.getValue('prev_charge_amount') && (
                        <span className="max-w-[500px]">
                            {((row.getValue('charge_amount') - row.getValue('prev_charge_amount')) / row.getValue('prev_charge_amount')).toPrecision(3) * 100}%
                        </span>
                    )}
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
    ];

    return <DataTable loading={isLoading} data={tableData} columns={columns} isMultiSelectRows={false} />;
};

export default CustomerChargeDashboard;

import * as z from 'zod';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, SheetFooter } from '../../common/sheet.tsx';
import StorageFeeForm from './forms/storageFeesForm';
import LoadingSpinner from '../../common/loadingSpinner';
import useStorageApi from '../../../hooks/api/useStorageApi';

const StorageFeesCreate = ({ locationData, customerData }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [cronString, setCronString] = useState();
    const cronValidationRef = React.useRef(() => {});
    const { createStorageFee } = useStorageApi();

    const storageFeeCreateFormDefaultValues = {
        name: '',
        location_type_ids: [],
        all_customers: false,
        all_locations: false,
        customer_ids: [],
        exclude_empty_locations_from_charge: false,
        charge_rate_type: null,
        charge_amount: 0,
    };

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const storageFeeSchema = z
        .object({
            name: z.string().trim().min(3, { message: 'Required' }),
            location_type_ids: z.array(dropDownSchema).optional(),
            all_customers: z.boolean(),
            all_locations: z.boolean(),
            customer_ids: z.array(dropDownSchema).optional(),
            exclude_empty_locations_from_charge: z.boolean().optional(),
            charge_rate_type: dropDownSchema,
            charge_amount: z.coerce.number().nullish(),
        })
        .refine(
            (input) => {
                if (!input.all_customers && input.customer_ids.length === 0) {
                    return false;
                }

                return true;
            },
            {
                message: 'Customer selection is required if this fee does not apply to all customers.',
                path: ['customer_ids'],
            },
        )
        .refine(
            (input) => {
                if (!input.all_locations && input.location_type_ids.length === 0) {
                    return false;
                }

                return true;
            },
            {
                message: 'Location selection is required if this fee does not apply to all locations.',
                path: ['location_type_ids'],
            },
        );
    const formProps = useForm({
        resolver: zodResolver(storageFeeSchema),
        defaultValues: storageFeeCreateFormDefaultValues,
    });

    const { handleSubmit, reset } = formProps;

    const onSubmit = (values) => {
        setIsLoading(true);
        createStorageFee({
            name: values.name,
            location_type_ids: values.location_type_ids.map((location) => location.value),
            all_customers: values.all_customers,
            all_locations: values.all_locations,
            customer_ids: values.customer_ids.map((customer) => customer.value),
            exclude_empty_locations_from_charge: values.exclude_empty_locations_from_charge,
            charge_type: values.charge_rate_type.value,
            charge_amount: values.charge_amount,
            charge_calculation_cadence: cronString,
            active: true,
        }).then((resp) => {
            if (resp.success) {
                setIsLoading(false);
                setIsDialogOpen(false);
                window.location.reload();
            }
        });
    };

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button
                    onClick={() => {
                        reset();
                        setIsDialogOpen(true);
                    }}
                    size="sm"
                    className="ml-4 h-8 text-sm"
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Create Storage Fee
                </Button>
            </SheetTrigger>
            {isDialogOpen && (
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">Create New Storage Fee</SheetTitle>
                        <SheetDescription className="text-rails-dark-blue">
                            <StorageFeeForm
                                formProps={formProps}
                                setCronString={setCronString}
                                cronValidationRef={cronValidationRef}
                                locationData={locationData}
                                customerData={customerData}
                            />
                        </SheetDescription>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <Button disabled={isLoading} onClick={handleSubmit((e) => onSubmit(e))} type="submit">
                                {isLoading && (
                                    <>
                                        <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                        Creating
                                    </>
                                )}
                                {!isLoading && <>Create</>}
                            </Button>
                        </div>
                    </SheetFooter>
                </SheetContent>
            )}
        </Sheet>
    );
};

export default StorageFeesCreate;

import * as z from 'zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, SheetFooter } from '../../common/sheet.tsx';
import useInvoiceApi from '../../../hooks/api/useInvoiceApi';
import { transformRule } from '../../../utils/invoice';
import PricingRuleForm from './forms/pricingRuleForm';
import LoadingSpinner from '../../common/loadingSpinner';

const PricingRuleEdit = ({ pricingRule, setPricingRule, ruleConditionMetadata }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [ruleId, setRuleId] = useState(pricingRule.id);
    const [isLoading, setIsLoading] = useState(false);
    const { getInvoiceCustomers, getRule, updateRule } = useInvoiceApi();

    const dropDownSchema = z.object({
        label: z.string(),
        value: z.string(),
    });

    const pricingRuleConditionsSchema = z.object({
        col: dropDownSchema,
        filter: dropDownSchema,
        value: z.string().min(1, { message: 'Required' }).optional().or(dropDownSchema.optional()).or(z.array(dropDownSchema)),
    });

    const pricingTierRangeConditionSchema = z.object({
        rangeCol: z.string().nullable().optional(),
        rangeStart: z.coerce.string().nullable().optional(),
        rangeEnd: z.coerce.string().nullable().optional(),
    });

    const pricingChoice = z.object({
        method: z.string().trim().min(1, { message: 'Method is required.' }),
        value: z.string().min(1, { message: 'Value is required.' }),
        col: z.string().nullable(),
    });

    const pricingTierSchema = z
        .object({
            tier_conditions: pricingTierRangeConditionSchema.optional(),
            pricing_choices: z.array(pricingChoice),
            pricing_choices_filter: z.string().nullable().optional(),
        })
        .refine(
            (input) => {
                const hasSpecialMethod = input.pricing_choices.some((choice) => choice.method === 'variable_cost' || choice.method === 'cumulative');
                if (hasSpecialMethod) {
                    if (!input.tier_conditions) {
                        return false;
                    }
                    const { rangeCol, rangeStart } = input.tier_conditions;
                    if (!rangeCol || !rangeStart) {
                        return false;
                    }
                }
                return true;
            },
            {
                message: 'Defining tier conditions is required when the method is "variable_cost" or "cumulative". Please ensure both rangeCol and rangeStart are provided.',
                path: ['tier_conditions'],
            },
        )
        .refine(
            (input) => {
                // Require pricing_choices_filter if there is more than one pricing_choice
                if (input.pricing_choices.length > 1 && !input.pricing_choices_filter) {
                    return false;
                }
                return true;
            },
            {
                message: 'Please select a selection method if more than one pricing type is entered',
                path: ['tier_conditions'],
            },
        );

    const pricingRuleSchema = z
        .object({
            name: z.string().trim().min(3, { message: 'Required' }),
            default: z.boolean(),
            rule_conditions: z.array(pricingRuleConditionsSchema).optional(),
            charge_type: dropDownSchema,
            tiers: z.array(pricingTierSchema),
            customer_ids: z.array(dropDownSchema).optional(),
            group_ids: z.array(dropDownSchema).optional(),
            tiers_are_cumulative: z.boolean(),
        })
        .refine(
            (input) => {
                if (!input.default && input.customer_ids.length === 0) {
                    return false;
                }

                return true;
            },
            {
                message: 'Customer selection is required if this rule is not a default rule.',
                path: ['customer_ids'],
            },
        );

    const formProps = useForm({
        resolver: zodResolver(pricingRuleSchema),
        defaultValues: pricingRule,
    });

    const { handleSubmit, reset } = formProps;

    const onSubmit = (values) => {
        setIsLoading(true);

        updateRule(ruleId, values).then((resp) => {
            if (resp.success) {
                getRule(resp.data).then((ruleData) => {
                    setRuleId(resp.data);
                    getInvoiceCustomers().then((customerList) => {
                        const transformedRuleData = transformRule(ruleData.data, ruleConditionMetadata, customerList);
                        setPricingRule(transformedRuleData);
                        setIsLoading(false);
                        setIsDialogOpen(false);
                    });
                });
            }
        });
    };

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                    className="ml-4"
                >
                    Edit
                </Button>
            </SheetTrigger>
            {isDialogOpen && (
                <SheetContent position="right" size="lg">
                    <SheetHeader className="pb-36 overflow-y-auto h-full">
                        <SheetTitle className="text-3xl">Edit Pricing Rule</SheetTitle>
                        <SheetDescription className="text-rails-dark-blue">
                            <PricingRuleForm formProps={formProps} ruleConditions={ruleConditionMetadata} />
                        </SheetDescription>
                    </SheetHeader>
                    <SheetFooter className="border-t-2 border-t-gray-50 backdrop-blur-sm bg-white/10 w-full sticky bottom-0 right-0 pr-6 pt-8 pb-8">
                        <div>
                            <Button disabled={isLoading} onClick={handleSubmit((e) => onSubmit(e))} type="submit">
                                {isLoading && (
                                    <>
                                        <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                                        Saving
                                    </>
                                )}
                                {!isLoading && <>Save</>}
                            </Button>
                        </div>
                    </SheetFooter>
                </SheetContent>
            )}
        </Sheet>
    );
};

export default PricingRuleEdit;

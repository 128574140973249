import { UsersIcon, PencilSquareIcon, AdjustmentsHorizontalIcon, ArchiveBoxXMarkIcon, GlobeAltIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';

import Select from 'react-select';

import { Input } from '../../../common/input.tsx';
import { Form, FormField, FormItem, FormControl, FormMessage } from '../../../common/form.tsx';
import { Switch } from '../../../common/switch.tsx';
import CronSchedule from '../../../common/cronSchedule';

export const storageChargeType = [
    {
        label: 'per Item Rate',
        value: 'PER_ITEM',
    },
    {
        label: 'per Location Rate',
        value: 'PER_LOCATION',
    },
    {
        label: 'Volume Rate',
        value: 'PER_UNIT_VOLUME',
    },
];

export const volumeUnit = [
    {
        label: 'cu inch',
        value: 'CUBIC_INCH',
    },
    {
        label: 'cu feet',
        value: 'CUBIC_FEET',
    },
    {
        label: 'cu centimeter',
        value: 'CUBIC_CENTIMETER',
    },
    {
        label: 'cu meter',
        value: 'CUBIC_METER',
    },
];

const StorageFeeForm = ({ formProps, setCronString, cronValidationRef, locationData, customerData }) => (
    <Form {...formProps}>
        <form className="space-y-8">
            <div className="pt-4">
                <div className="flex text-xl font-medium">
                    <PencilSquareIcon className="h-6 w-6 mr-2" />
                    Name
                </div>
                <FormField
                    control={formProps.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input className="mt-2" placeholder="Enter pricing rule name" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-medium">
                    <GlobeAltIcon className="h-6 w-6 mr-2" />
                    For all locations
                </div>
                <div className="mt-2 flex items-center space-x-4">
                    <FormField
                        control={formProps.control}
                        name="all_locations"
                        render={({ field }) => (
                            <>
                                <FormItem>
                                    <FormControl>
                                        <Switch onCheckedChange={field.onChange} checked={field.value} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                                <span>Apply to all locations?</span>
                            </>
                        )}
                    />
                </div>
            </div>
            {!formProps.getValues('all_locations') && (
                <div className="pt-4">
                    <div className="flex text-xl font-medium">
                        <AdjustmentsHorizontalIcon className="h-6 w-6 mr-2" />
                        Locations
                    </div>
                    <div className="mt-2">
                        <FormField
                            control={formProps.control}
                            name="location_type_ids"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            isMulti
                                            options={locationData}
                                            placeholder="Locations"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DBF3D8',
                                                    primary: '#92BAA3',
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
            )}
            <div className="pt-4">
                <div className="flex text-xl font-medium">
                    <GlobeAltIcon className="h-6 w-6 mr-2" />
                    For all customers
                </div>
                <div className="mt-2 flex items-center space-x-4">
                    <FormField
                        control={formProps.control}
                        name="all_customers"
                        render={({ field }) => (
                            <>
                                <FormItem>
                                    <FormControl>
                                        <Switch onCheckedChange={field.onChange} checked={field.value} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                                <span>Apply to all customers?</span>
                            </>
                        )}
                    />
                </div>
            </div>
            {!formProps.getValues('all_customers') && (
                <div className="pt-4">
                    <div className="flex text-xl font-medium">
                        <UsersIcon className="h-6 w-6 mr-2" />
                        Customers
                    </div>
                    <div className="mt-2">
                        <FormField
                            control={formProps.control}
                            name="customer_ids"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Select
                                            {...field}
                                            isMulti
                                            options={customerData}
                                            placeholder="Customer Names"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DBF3D8',
                                                    primary: '#92BAA3',
                                                },
                                            })}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
            )}
            <div className="pt-4">
                <div className="flex text-xl font-medium">
                    <AdjustmentsHorizontalIcon className="h-6 w-6 mr-2" />
                    Charging Cadence
                </div>
                <div className="mt-2">
                    <CronSchedule
                        className="mt-2"
                        formProps={formProps}
                        setCronString={setCronString}
                        cronValidationRef={cronValidationRef}
                        initialCronString={formProps.getValues().charge_calculation_cadence}
                        allowSetHourly={false}
                    />
                </div>
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-medium">
                    <ArchiveBoxXMarkIcon className="h-6 w-6 mr-2" />
                    Exclude empty locations from charge?
                </div>
                <div className="mt-2 flex items-center space-x-4">
                    <FormField
                        control={formProps.control}
                        name="exclude_empty_locations_from_charge"
                        render={({ field }) => (
                            <>
                                <FormItem>
                                    <FormControl>
                                        <Switch onCheckedChange={field.onChange} checked={field.value} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                                <span>If no items are present at a location, should we exclude it from storage charges?</span>
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="pt-4">
                <div className="flex text-xl font-medium">
                    <CurrencyDollarIcon className="h-6 w-6 mr-2" />
                    Charging Rates
                </div>
                <div className="mt-2">
                    <FormField
                        control={formProps.control}
                        name="charge_rate_type"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Select
                                        {...field}
                                        options={storageChargeType}
                                        placeholder="Charging Rate Type"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#DBF3D8',
                                                primary: '#92BAA3',
                                            },
                                        })}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                {formProps.watch('charge_rate_type')?.value === 'PER_ITEM' && (
                    <div className="mt-2">
                        <span>Per product stored relative to location dimension. Price is rate for 100% usage.</span>
                        <FormField
                            control={formProps.control}
                            name="charge_amount"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input className="mt-2" placeholder="0" type="number" {...field} prefix="$" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                )}
                {formProps.watch('charge_rate_type')?.value === 'PER_LOCATION' && (
                    <div className="mt-2">
                        <span>Per occupied location</span>
                        <FormField
                            control={formProps.control}
                            name="charge_amount"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input className="mt-2" placeholder="0" type="number" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                )}
                {formProps.watch('charge_rate_type')?.value === 'PER_UNIT_VOLUME' && (
                    <div className="pt-4">
                        <div className="flex text-xl font-medium">
                            <AdjustmentsHorizontalIcon className="h-6 w-6 mr-2" />
                            Volume Used
                        </div>
                        <span>Fixed Cost for volume used.</span>
                        <div className="mt-2 flex space-x-2">
                            <div className="w-1/2">
                                <FormField
                                    control={formProps.control}
                                    name="charge_amount"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input className="mt-2" placeholder="0" type="number" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="w-1/2">
                                <FormField
                                    control={formProps.control}
                                    name="charge_amount_unit"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Select
                                                    {...field}
                                                    isMulti
                                                    options={volumeUnit}
                                                    placeholder="Per cu in, cu ft, cu cm, cu m."
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DBF3D8',
                                                            primary: '#92BAA3',
                                                        },
                                                    })}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {formProps.watch('charge_rate_type')?.value === 'PER_VOLUME_RATE' && (
                    <div className="mt-2">
                        <span>Per product dimension relative to location dimension. Price is rate for 100% usage.</span>
                        <FormField
                            control={formProps.control}
                            name="charge_amount"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input className="mt-2" placeholder="0" type="number" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                )}
            </div>
        </form>
    </Form>
);

export default StorageFeeForm;

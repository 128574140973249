import { useState, useEffect } from 'react';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/common/table.tsx';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/common/card.tsx';

import { ordersNeedingReviewInternalOrderNeedsReviewGet } from '@/client/services.gen.ts';
import { type OrdersNeedingReviewInternalOrderNeedsReviewGetResponse } from '@/client/types.gen.ts';

const Orders = () => {
    const [tableData, setTableData] = useState<OrdersNeedingReviewInternalOrderNeedsReviewGetResponse>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchOrders = async () => {
        setIsLoading(true);
        // TODO: this query in the backend is broken
        const response = await ordersNeedingReviewInternalOrderNeedsReviewGet();
        setTableData(response.data ?? []);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="grid grid-cols-4 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="col-span-3">
                                <h1 className="text-2xl font-bold text-rails-dark-blue">Orders Needing Review</h1>
                                <h2 className="text-xs text-rails-dark-blue">These orders were already invoiced, but additional data about them has come in through the WMS.</h2>
                                <div className="space-y-8">
                                    {
                                        // eslint-disable-next-line no-nested-ternary
                                        isLoading ? (
                                            <p>Loading...</p>
                                        ) : tableData.length === 0 ? (
                                            <p>No orders needing review.</p>
                                        ) : (
                                            tableData.map(([stagingOrder, order, lineItems, diffs], index) => (
                                                <Card key={index}>
                                                    <CardHeader>
                                                        <CardTitle>
                                                            Order {order.order_number} - {order.customer.name}
                                                        </CardTitle>
                                                    </CardHeader>
                                                    <CardContent>
                                                        <div className="grid grid-cols-2 gap-4 mb-4">
                                                            <div>
                                                                <strong>Order Number:</strong> {stagingOrder.order_number}
                                                            </div>
                                                            <div>
                                                                <strong>Order ID:</strong> {order.id}
                                                            </div>
                                                        </div>

                                                        <h3 className="text-lg font-semibold mb-2">Differences:</h3>
                                                        <Table>
                                                            <TableHeader>
                                                                <TableRow>
                                                                    <TableHead>Field</TableHead>
                                                                    <TableHead>New</TableHead>
                                                                    <TableHead>Existing</TableHead>
                                                                </TableRow>
                                                            </TableHeader>
                                                            <TableBody>
                                                                {Object.entries(diffs).map(([key, value], diffIndex) => (
                                                                    <TableRow key={diffIndex}>
                                                                        <TableCell>{key}</TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                // @ts-ignore
                                                                                JSON.stringify(value.instance1)
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                // @ts-ignore
                                                                                JSON.stringify(value.instance2)
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>

                                                        <h3 className="text-lg font-semibold mt-4 mb-2">Existing Line Items:</h3>
                                                        {lineItems.map(([invoiceId, items], idx) => (
                                                            <div key={idx} className="mb-4">
                                                                <h4 className="font-medium mb-2">Invoice: {invoiceId}</h4>
                                                                <Table>
                                                                    <TableHeader>
                                                                        <TableRow>
                                                                            <TableHead>Charge Type</TableHead>
                                                                            <TableHead>Amount</TableHead>
                                                                        </TableRow>
                                                                    </TableHeader>
                                                                    <TableBody>
                                                                        {items.map((item, itemIdx) => (
                                                                            <TableRow key={itemIdx}>
                                                                                <TableCell>{item.charge_type}</TableCell>
                                                                                <TableCell>{parseFloat(item.charge_amount).toFixed(2)}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </div>
                                                        ))}
                                                    </CardContent>
                                                </Card>
                                            ))
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Orders;
